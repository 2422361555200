import React, { useState } from "react"
import { Grid, Box } from '@mui/material';
import 'assets/css/Events.css'
import AddEventModal from './AddEventModal';

import FullCalendar from '@fullcalendar/react' // must go before plugins
import resourceTimeGridPlugin from '@fullcalendar/resource-timegrid';

import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-icons/font/bootstrap-icons.css'; // needs additional webpack config!
import bootstrap5Plugin from '@fullcalendar/bootstrap5';
import interactionPlugin from "@fullcalendar/interaction" // needed for dayClick

export default function Events() {

    const [modalShow, setmodalShow] = useState(false);

    const handleDateClick = (arg) => { // bind with an arrow function
        alert('sad');
    }

    const handleClose = () => {
        setmodalShow(false);
    }

    const handleShow = () => {
        setmodalShow(true);
    }


    const viewsPattern = {

        'resourceTimeGridFourDay': {
            type: 'resourceTimeGrid',
            duration: { days: 4 }
        }
    }

    const customButton = {
        create: {
            text: 'создать',
            click: function () {
                handleShow()
            }
        }

    }

    return (
        <>
            <AddEventModal show={modalShow} handleClose={handleClose} size="lg" centered />
            <Box >
                <Grid container mt={2} spacing={0}>

                    <Grid item xs={12} sm={12} >
                        <FullCalendar
                            schedulerLicenseKey="CC-Attribution-NonCommercial-NoDerivatives"
                            plugins={[bootstrap5Plugin, interactionPlugin, resourceTimeGridPlugin]}
                            initialView='resourceTimeGridFourDay'
                            views={viewsPattern}
                            customButtons={customButton}

                            headerToolbar={{ start: 'create', center: 'title', end: 'prev,next today' }}
                            themeSystem="bootstrap5"
                            locale={'ru'}
                            height={'auto'}
                            editable={true}
                            allDaySlot={false}
                            allDayText='Весь день'
                            slotLabelFormat={{
                                hour: 'numeric',
                                minute: '2-digit'
                            }}
                            slotMinTime="07:00"
                            slotMaxTime="21:00"
                            resources={[
                                {
                                    id: 'a',
                                    title: 'Кабинет 1',
                                    businessHours: [{
                                        startTime: '09:00',
                                        endTime: '19:00',
                                        daysOfWeek: [1, 2, 3, 4]
                                    },
                                    {
                                        startTime: '09:00',
                                        endTime: '16:00',
                                        daysOfWeek: [5]
                                    },
                                    {
                                        startTime: '10:00',
                                        endTime: '15:00',
                                        daysOfWeek: [6]
                                    }
                                    ]
                                },
                                {
                                    id: 'b',
                                    title: 'Кабинет 2',
                                    eventColor: "#737e3d"
                                }
                            ]}

                            events={
                                [
                                    // areas where "Events" must be dropped
                                    {
                                        groupId: "availableForEvent",
                                        resourceId: "a",
                                        start: "2022-09-07 09:00",
                                        end: "2022-09-07 20:00",
                                        display: "background"
                                    },
                                    {
                                        groupId: "availableForEvent",
                                        resourceId: "a",
                                        start: "2022-09-08 09:00",
                                        end: "2022-09-08 18:00",
                                        display: "background"
                                    },

                                    { id: 1, "resourceId": "a", "title": "Repeating Event", "start": "2022-09-09 16:00:00", "constraint": "availableForEvent" },
                                    { id: 2, "resourceId": "a", "title": "Conference", "start": "2022-09-04", "end": "2022-09-06", "constraint": "availableForEvent" },
                                    { id: 3, "resourceId": "a", "title": "Lunch", "start": "2022-09-07T12:00:00+00:00", "constraint": "availableForMeeting"},

                                    { id: 4, "resourceId": "b", "title": "Long Event", "start": "2022-09-07", "end": "2022-09-10" },
                                    { id: 5, "resourceId": "b", "title": "Meeting", "start": "2022-09-05T10:30:00+00:00", "end": "2022-09-05T12:30:00+00:00" },
                                    { id: 6, "resourceId": "b", "title": "Birthday Party", "start": "2022-09-06T07:00:00+00:00" },

                                ]
                            }


                        />
                    </Grid>
                </Grid>

            </Box>
        </>
    )
}
