import React, { useState, useEffect, useContext } from 'react';


import { useClients, useClient, useSaveClient, useDeleteClient } from 'hooks/client/useClients';
import { useClientCategoryies } from 'hooks/client/useClientCategoryies';
import { useAllergens, useIntolerances } from 'hooks/attr/useAttrs';
import { useInsuranceSubjects } from 'hooks/insuranceSubject/useInsuranceSubject';
import { isMobile } from 'react-device-detect';

import ClientBrowserFilter from './BrowserView/ClientBrowserFilter';
import ClientMobileFilter from './MobileView/ClientMobileFilter';
import ClientBrowserView from './BrowserView/ClientBrowserView';
import ClientMobileView from './MobileView/ClientMobileView';
import ClientFormModal from './ClientFormModal'
import ClientDeleteFormModal from './ClientDeleteFormModal'
import IsLoadingSkeleton from 'components/skeletons/IsLoadingSkeleton'

import { Context } from 'context';



export default function Clients(props) {

    const [filtred_clients, setFiltredClients] = useState([])
    const [filter, setFilter] = useState({})
    const [buttonAction, setButtonAction] = useState(null)
    const [current_client, setCurrentClient] = useState(null)
    const [modalShow, setModalShow] = useState(false)
    const [deleteModalShow, setDeleteModalShow] = useState(false);
    const { handlerLoadProgress, errorLoadProgress } = useContext(Context)

    const { data: clients, isLoading: isLoadingCl, isFetching: isFetchingCl, isErrorC, refetch } = useClients(filter)
    const { data: clientForm, isLoading: isLoadingClient, isError: isErrorClient } = useClient(current_client)
    const { mutateAsync: saveClient, isError: isErrorSave } = useSaveClient()
    const { mutateAsync: deleteClient, isError: isErrorDelete } = useDeleteClient()
    const { data: client_categories, isLoading: isLoadingCC, isErrorCC } = useClientCategoryies()
    const { selectItems: allergy_list, isLoading: isLoadingAl, isErrorAl } = useAllergens()
    const { selectItems: intolerance_list, isLoading: isLoadingIn, isErrorIn } = useIntolerances()
    const { selectItems: insurance_subjects, isLoading: isLoadingIS, isErrorIS } = useInsuranceSubjects()

    const isLoading = isLoadingCl || isFetchingCl || isLoadingCC || isLoadingAl || isLoadingIn || isLoadingIS;

    useEffect(() => {
        if (isErrorC) {
            errorLoadProgress('Ошибка загрузки клиентов');
        }
    }, [isErrorC, errorLoadProgress])

    useEffect(() => {
        if (isErrorCC) {
            errorLoadProgress('Ошибка загрузки категорий клиентов');
        }
    }, [isErrorCC, errorLoadProgress])

    useEffect(() => {
        if (isErrorAl) {
            errorLoadProgress('Ошибка загрузки списка алергенов');
        }
    }, [isErrorAl, errorLoadProgress])

    useEffect(() => {
        if (isErrorIn) {
            errorLoadProgress('Ошибка загрузки списка непереносимостей');
        }
    }, [isErrorIn, errorLoadProgress])

    useEffect(() => {
        if (isErrorIS) {
            errorLoadProgress('Ошибка загрузки списка страховых компаний');
        }
    }, [isErrorIS, errorLoadProgress])

    useEffect(() => {
        if (isErrorClient) {
            errorLoadProgress('Ошибка получения данных клиента');
            refetch()
        }
    }, [isErrorClient, errorLoadProgress, refetch])

    useEffect(() => {
        if (isErrorSave) {
            errorLoadProgress('Ошибка сохранения клиента');
        }
    }, [isErrorSave, errorLoadProgress])

    useEffect(() => {
        if (isErrorDelete) {
            errorLoadProgress('Ошибка удаления клиента');
        }
    }, [isErrorDelete, errorLoadProgress])


    useEffect(() => {
        setFiltredClients(clients)
    }, [isLoadingCl, isFetchingCl, clients])


    useEffect(() => {
        handlerLoadProgress(isLoadingClient);
        if (!isLoadingClient && current_client !== null && !isErrorClient) {
            if (buttonAction === 'editButton') {
                setModalShow(true);
            } else if (buttonAction === 'deleteButton') {
                setDeleteModalShow(true);
            }
        }
    }, [isLoadingClient, current_client, handlerLoadProgress, buttonAction, isErrorClient])



    const changeFilter = (name, value) => {
        setFilter({ ...filter, ...{ [name]: value } });
    }

    const filterButtonHandler = () => {
        console.log('filter==', filter)
    }

    const addButtonHandler = () => {
        editButtonHandler('create')
    }

    const editButtonHandler = (id) => {
        setButtonAction('editButton');
        if (id === current_client) {
            setModalShow(true);
        } else {
            setCurrentClient(id)
        }
    }

    const deleteButtonHandler = (id) => {
        setButtonAction('deleteButton');
        if (id === current_client) {
            setDeleteModalShow(true);
        } else {
            setCurrentClient(id)
        }
    }

    const handleClose = () => {
        setModalShow(false);
        setDeleteModalShow(false);
    }

    const handleSave = async (data) => {
        saveClient(data).then(() => {
            setModalShow(false)
        })
    }


    const handleDelete = (data) => {
        deleteClient(data?.id || 0).then(() => {
            setDeleteModalShow(false)
        })
    }


    const searchByFilter = (text) => {

        let filtred = [];
        if (typeof text.length !== 'undefined' && text.length > 0) {
            filtred = clients.filter(function (item) {
                let compare_string = item.lastname + ' ' + item.firstname + ' ' + item.patronymic + '' + item.phone.replace(/_/gi, '').replace(/-/gi, '').replace(/ /gi, '');
                console.log('compare_string', compare_string);

                let re = new RegExp(text, 'gi');
                return compare_string.search(re) !== -1 ? true : false;

            });
        } else {
            filtred = clients
        }
        setFiltredClients(filtred);
    }



    return (
        <>
            <ClientFormModal show={modalShow} handleClose={handleClose} handleSave={handleSave}
                client={clientForm}
                client_categories={client_categories}
                insurance_subjects={insurance_subjects}
                allergy_list={allergy_list}
                intolerance_list={intolerance_list}
            />
            <ClientDeleteFormModal show={deleteModalShow} handleClose={handleClose} handleDelete={handleDelete} client={clientForm} />

            {
                !isLoading
                    ?
                    <>
                        {
                            isMobile
                                ? <ClientMobileFilter client_categories={client_categories} addButtonHandler={addButtonHandler} searchByFilter={searchByFilter} />
                                : <ClientBrowserFilter filter={filter} client_categories={client_categories} addButtonHandler={addButtonHandler} filterButtonHandler={filterButtonHandler} changeFilter={changeFilter} />
                        }

                        {isMobile
                            ? <ClientMobileView clients={filtred_clients} editButtonHandler={editButtonHandler} deleteButtonHandler={deleteButtonHandler} />
                            : <ClientBrowserView clients={filtred_clients} editButtonHandler={editButtonHandler} deleteButtonHandler={deleteButtonHandler} />
                        }
                    </>
                    :
                    <IsLoadingSkeleton />
            }


        </>
    );
}
