import React, { useState } from "react"
import { Box } from "@mui/system"
import ReCAPTCHA from "react-google-recaptcha";
import { Paper, TextField, Avatar, Grid, Typography, Link } from "@mui/material"
import LoadingButton from '@mui/lab/LoadingButton';
import { AppConfig } from 'config.js';

import AuthHeader from 'pages/auth/AuthHeader'
import icon from 'assets/icons/free-icon-forgot-password-6195696.png';
import iconEmail from 'assets/icons/email-checked.png';

export default function AuthRecoveryRequest(props) {

    const [email, setEmail] = useState('');
    const [isSended, setIsSended] = useState(false);
    const [isProgress, setisProgress] = useState(false);
    const [isVerifed, setisVerifed] = useState(false);

    const handleisVerifed = () => {
        setisVerifed(true);
    }

    const sendRecoveryPasswordRequest = () => {

        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        var oauth_url = AppConfig.apiUrl + '/user/pass_recovery/request';

        var raw = JSON.stringify(
            {
                "email": email,
                "callback": AppConfig.appUrl + '/AuthRecovery',
                "client_id": AppConfig.client_id,
                "client_secret": AppConfig.client_secret
            }
        );

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw
        };

        setisProgress(true);

        fetch(oauth_url, requestOptions)
            .then(response => response.json())
            .then(result => {
                setisProgress(false);
                if (result.status = true) {
                    setIsSended(true);
                } else {
                }
            })
            .catch(
                error => {
                    setisProgress(false);
                    console.log('error', error)
                }
            );
    }


    return (
        <Paper elevation={10} style={props.paperStyle}>
            <Box p={3}>
                {!isSended ?
                    <Grid container rowSpacing={2} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                        <Grid item xs={12} align='center'>
                            <AuthHeader src={icon} title='Востановление доступа' />
                        </Grid>
                        <Grid item xs={12}>
                            <Box mt={1}>
                                <TextField size="small" required onChange={(e) => { setEmail(e.target.value) }} label="Введите email пользователя" fullWidth />
                            </Box>
                        </Grid>

                        <Grid item xs={12}>
                            <Box>
                                <ReCAPTCHA
                                    sitekey={AppConfig.sitekey}
                                    render="explicit"
                                    size="normal"
                                    onChange={handleisVerifed}
                                />
                            </Box>
                        </Grid>
                        <Grid item xs={12}>
                            <LoadingButton type='submit'
                                color='primary'
                                size="small"
                                variant="contained"
                                onClick={sendRecoveryPasswordRequest}
                                loading={isProgress}
                                loadingIndicator="Отправка..."
                                disabled={!isVerifed}
                                fullWidth >
                                Востановить
                            </LoadingButton>
                        </Grid>
                        <Grid item xs={12} align='right'>
                            <Link size="small" href="/AuthForm">я вспомнил пароль</Link>
                        </Grid>
                    </Grid>
                    :
                    <Box>
                        <Grid container rowSpacing={2} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                            <Grid item xs={12} align='center'>
                                <Avatar src={iconEmail} sx={{ width: 64, height: 64 }} variant='square' />
                                <Typography variant="body1" mt={2} align='center'>Код подтверждения был выслан на Email, следуйте инструкциям в письме</Typography>
                            </Grid>
                            <Grid item xs={6}></Grid>
                            <Grid item xs={6}>
                                <Link size="small" href="/AuthForm">Ввести пароль</Link>
                            </Grid>
                        </Grid>
                    </Box>
                }
            </Box>

        </Paper>
    )
}
