import axios from 'axios';
import { Promise } from "es6-promise";
import { AppConfig } from './../config.js';

axios.defaults.headers.common['Client_ip'] = 'clientIP'
const auth = axios.create();

let isRefreshing = false;
let refreshSubscribers = [];

auth.interceptors.request.use(
  function (config) {
    // Do something before request is sent
    const access_token = getAcessToken();
    if (access_token) {
      config.headers.Authorization = `Bearer ${access_token}`;
    }
    return config;
  },
  function (error) {
    // Do something with request error
    return Promise.reject(error);
  }
);

auth.interceptors.response.use((response) => {
  // Return a successful response back to the calling service
  return response;
}, (error) => {

  const { config, response: { status } } = error;
  const originalRequest = config;

  if (status === 401) {
    if (!isRefreshing) {
      isRefreshing = true;
      console.log('isRefreshing', isRefreshing);
      refreshAccessToken()
        .then(newToken => {
          isRefreshing = false;
          onRrefreshed(newToken);
        });
    }

    const retryOrigReq = new Promise((resolve, reject) => {
      subscribeTokenRefresh(token => {
        originalRequest.headers['Authorization'] = 'Bearer ' + getAcessToken();
        resolve(auth(originalRequest));
      });
    });

    return retryOrigReq;
  } else {
    return Promise.reject(error);
  }



  function refreshAccessToken() {
    let oauth_url = AppConfig.oauthUrl + '/oauth/token';
    let refresh_token = getRefreshToken();
    var params = {
      grant_type: 'refresh_token',
      refresh_token: refresh_token,
      client_id: AppConfig.client_id,
      client_secret: AppConfig.client_secret
    };

    return axios.post(oauth_url, params)
      .then(response => {
        storeAccessToken(response.data.access_token);
        storeRefreshToken(response.data.refresh_token);
      }).catch(error => {
         localStorage.clear();
         window.location.replace(AppConfig.appUrl);
      });
  }

  function subscribeTokenRefresh(cb) {
    refreshSubscribers.push(cb);
  }
  function onRrefreshed(token) {
    refreshSubscribers.map(cb => cb(token));
  }

});


function getAcessToken() {
  return localStorage.getItem('access_token');
}

function storeAccessToken(value) {
  localStorage.setItem('access_token', value);
}

function getRefreshToken() {
  return localStorage.getItem('refresh_token');
}

function storeRefreshToken(value) {
  localStorage.setItem('refresh_token', value);
}

function clear() {
  localStorage.removeItem('access_token');
  localStorage.removeItem('refresh_token');
}

export const setAccessToken = (value) => {
  storeAccessToken(value);
}
export const getAccessToken = () => {
  return localStorage.getItem('access_token');
}

export const clearTokens = () => {
  clear();
}

export default auth

