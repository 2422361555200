import React from "react";

import { Box } from "@mui/material";

import ClientItem from "./ClientItem";

export default function ClientMobileView(props) {
    return (
        <Box mt={2}>
            {props.clients.map((item, index) => (
                <ClientItem key={index} {...props} {...item} />
            ))}
        </Box>
    )


}