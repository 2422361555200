import api from 'helpers/api';
import { AppConfig } from 'config.js';
import { useQuery, useMutation, useQueryClient } from "react-query";


export const useFilialRoomTypes = () => {
    const loadFilialRoomTypes = async () => {
        return await api.get(AppConfig.apiUrl + '/filial_room_types');
    }

    const { data: data, isLoading, isError } = useQuery(
        ['filial_room_types'],
        loadFilialRoomTypes,
        {
            staleTime: 5 * 60 * 1000
        }
    );

    let filial_room_types = data?.data || [];
    return { filial_room_types, isLoading, isError }
}

