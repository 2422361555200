import api from 'helpers/api';
import { AppConfig } from 'config.js';
import { useQuery } from "react-query";


export const useWeekDays = () => {


    const { data: data, isLoading, isError } = useQuery(
        ['week_days'],
        async () => {
            return await api.get(AppConfig.apiUrl + '/prodCalendar/weekdays');
        },
        {
            staleTime: 5 * 60 * 1000
        }
    );


    return { week_days: data?.data || [], isLoading, isError }
}