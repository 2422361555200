import React from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';


const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
}));

const BootstrapDialogTitle = (props) => {
    const { children, onClose, ...other } = props;

    return (
        <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
            {children}
            {onClose ? (
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
            ) : null}
        </DialogTitle>
    );
};

BootstrapDialogTitle.propTypes = {
    children: PropTypes.node,
    onClose: PropTypes.func.isRequired,
};


export default function ClientDeleteFormModal(props) {

    const client = { ...props.client }

    const handleDelete = () => {
        props.handleDelete(client);
    }

    const handleClose = () => {
    }


    return (
        <>
            <BootstrapDialog open={props.show} onClose={props.handleClose} size="lg" >
                <BootstrapDialogTitle id="customized-dialog-title" onClose={props.handleClose}>
                    Удаление клиента
                </BootstrapDialogTitle>
                <DialogContent dividers>
                    <Box sx={{ textAlign: 'center' }} mt={2}>
                        <Typography>Удалить клиента <b>{props.client.lastname} {props.client.firstname}</b>, тел: {props.client.phone}</Typography>
                        <Typography variant="caption">Востановление возможно, через системного администратора</Typography>
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button variant="secondary" onClick={props.handleClose}>
                        Закрыть
                    </Button>
                    <Button variant="danger" onClick={handleDelete}>
                        Удалить
                    </Button>
                </DialogActions>
            </BootstrapDialog>
        </>
    )
}