import React from 'react';
import PropTypes from 'prop-types';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import OutlinedInput from '@mui/material/OutlinedInput';
import FormHelperText from '@mui/material/FormHelperText';
import { IMaskInput } from 'react-imask';

//126-029-036 24
const Mask = /^\d{11}$/;
const ViewMask = '000-000-000 00';

const clearVal = (val) => {
  return val.replace(/_/gi, '').replace(/-/gi, '').replace(/ /gi, '');
}

const TextMaskCustom = React.forwardRef(function TextMaskCustom(props, ref) {
  const { onChange, ...other } = props;
  return (
    <IMaskInput
      {...other}
      mask={ViewMask}
      definitions={{
        '#': Mask,
      }}
      lazy={true}
      inputRef={ref}
      onAccept={(value) => onChange({ target: { name: props.name, value } })}
      overwrite
    />
  );
});

TextMaskCustom.propTypes = {
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default function InputSnilsField(props) {

  const defaults = { label: 'Label', name: 'text-mask-input', size: 'small', required: false, variant: 'outlined', show_error: false };
  props = { ...defaults, ...props };

  const [value, setValue] = React.useState('')
  const [isDirtyField, setIsDirtyField] = React.useState(false)
  const [isValid, setIsValid] = React.useState(false)
  const [error, setError] = React.useState({ code: 0, text: '' })

  const handleChange = event => {
    event.target.value = clearVal(event.target.value);
    setValue(event.target.value);

    const { result, error } = handleValid(event.target.value, props.re);
    event.target.isValid = result;
    setIsValid(result);
    setError(error);
    props.onChange(event)
  }


  const handleValid = (snils) => {

    let result = false;
    let error = {};

    if (typeof snils === 'number') {
      snils = snils.toString();
    } else if (typeof snils !== 'string') {
      snils = '';
    }
    if (!snils.length) {
      error.code = 1;
      error.text = 'СНИЛС пуст';
    } else if (/[^0-9]/.test(snils)) {
      error.code = 2;
      error.text = 'СНИЛС может состоять только из цифр';
    } else if (snils.length !== 11) {
      error.code = 3;
      error.text = 'СНИЛС может состоять только из 11 цифр';
    } else {
      var sum = 0;
      for (var i = 0; i < 9; i++) {
        sum += parseInt(snils[i]) * (9 - i);
      }
      var checkDigit = 0;
      if (sum < 100) {
        checkDigit = sum;
      } else if (sum > 101) {
        checkDigit = parseInt(sum % 101);
        if (checkDigit === 100) {
          checkDigit = 0;
        }
      }
      if (checkDigit === parseInt(snils.slice(-2))) {
        result = true;
      } else {
        error.code = 4;
        error.text = 'Неправильное контрольное число';
      }
    }
    return { result, error }
  }



  React.useEffect(() => {
    setValue(props.value);
  }, [props]);


  return (
    <FormControl {...props} show_error="false">
      <InputLabel >{props.label}</InputLabel>
      <OutlinedInput
        helpertext={props.helperText}
        error={isDirtyField && !isValid}
        onChange={handleChange}
        onBlur={() => setIsDirtyField(true)}
        label={props.label}
        name={props.name}
        value={value}
        inputComponent={TextMaskCustom}
      />
      {props.show_error && error.text ? <FormHelperText id="outlined-helper-text">{error.text}</FormHelperText> : ''}
    </FormControl>
  );
}