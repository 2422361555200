import React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import OutlinedInput from '@mui/material/OutlinedInput';
import FormHelperText from '@mui/material/FormHelperText';
import { IMaskInput } from 'react-imask';

const EmailMask = /^\S*@?\S*$/;
const TextMaskCustom = React.forwardRef(function TextMaskCustom(props, ref) {
  const { onChange, ...other } = props;
  return (
    <IMaskInput
      {...other}
      mask={EmailMask}
      lazy={false}
      inputRef={ref}
      onAccept={(value) => onChange({ target: { name: props.name, value } })}
      overwrite
    />
  );
});

TextMaskCustom.propTypes = {
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default function InputEmailField(props) {
  
  const defaults = { label: 'Email', name: 'text-mask-input', size: 'small', lazy: false, wrong: {state:false,text:''} };
  props={...defaults,...props};

  const [value, setValue] = React.useState('')
  const [isDirtyField, setIsDirtyField] = React.useState(false)
  const [isValid, setIsValid] = React.useState(false)
  
  const handleChange = event => {
    setValue(event.target.value);
    props.wrong.state = false;
    props.wrong.text = '';
    event.target.isValid = handleValid(event.target.value);
    props.onChange(event)
  }


  const handleValid=(val)=>{
    let result= /^[^@]+@\w+(\.\w+)+\w$/.test(val);
    setIsValid(result);
    return result;
  }

  React.useEffect(() => {
    setValue(props.value);
  }, [props]);

  return (
     <FormControl variant="outlined" fullWidth  size={props.size} >
        <InputLabel >{props.label}</InputLabel>
        <OutlinedInput                  
          helpertext={props.helperText}          
          error={isDirtyField && !isValid || props.wrong.state}
          onChange={handleChange}
          onBlur={() => setIsDirtyField(true)}
          label={props.label}
          name={props.name}
          value={value}
          inputComponent={TextMaskCustom}
        />
        {props.wrong.text ? <FormHelperText id="outlined-helper-text">{props.wrong.text}</FormHelperText> : ''}
        </FormControl>
  );
}