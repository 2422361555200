import React from 'react';
import { Stack, Skeleton } from '@mui/material'

export default function IsLoadingSkeleton() {
    return (
        <Stack spacing={1} mt={1}>
            <Skeleton variant="rounded" height={100} />
            <Skeleton variant="rounded" height={600} />
        </Stack>
    )
}