import React, { useState, useEffect } from 'react';
import api from 'helpers/api';
import { AppConfig } from 'config.js';
import { Box, FormControl, Grid, InputLabel, MenuItem, Select, Stack, Typography } from '@mui/material';
import FormSelect from 'components/custom/inputs/FormSelect';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';



export default function (props) {

    return (
        <Box mt={3}>
            <Grid container spacing={2}>
                <Grid item xs={4} >
                    <Box pr={2}>
                        <FormControl fullWidth>
                            <InputLabel id="schedule_place-label">Рабочее место</InputLabel>
                            <Select
                                labelId="schedule_place-label"
                                id="schedule_place"
                                value={props.workplace_id || "0"}
                                label="Рабочее место"
                                size="small"
                                onChange={(e) => props.changeScheduleItem('workplace_id', e.target.value)}
                            >
                                <MenuItem value={"0"} >--</MenuItem>
                                {props.places.map((item, index) =>
                                (
                                    <MenuItem value={item.id} key={'place_' + index} >{item.name}</MenuItem>
                                )
                                )}
                            </Select>
                        </FormControl>
                    </Box>
                </Grid>

                <Grid item xs={3}>
                    <Stack direction="row" spacing={1} >
                        <Box>
                            <FormControl>
                                <InputLabel id="time_start-label">c</InputLabel>
                                <Select
                                    labelId="time_start-label"
                                    id="time_start"
                                    value={props.time_start || '00:00'}
                                    label="с"
                                    onChange={(e) => props.changeScheduleItem('time_start', e.target.value)}
                                    size="small"
                                    disabled={props.disabled}
                                >
                                    {props.times.map((item, index) =>
                                    (
                                        <MenuItem value={item} key={props.name + 'time_start' + index} >{item}</MenuItem>
                                    )
                                    )}
                                </Select>
                            </FormControl>
                        </Box>
                        <Typography pt={1}>-</Typography>
                        <Box>
                            <FormControl>
                                <InputLabel id="time_end-label">по</InputLabel>
                                <Select
                                    labelId="time_end-label"
                                    id="time_end"
                                    value={props.time_end || '00:00'}
                                    label="по"
                                    onChange={(e) => props.changeScheduleItem('time_end', e.target.value)}
                                    size="small"
                                    disabled={props.disabled}
                                >
                                    {props.times.map((item, index) =>
                                    (
                                        <MenuItem value={item} key={props.name + 'time_end' + index}>{item}</MenuItem>
                                    )
                                    )}
                                </Select>
                            </FormControl>
                        </Box>
                    </Stack>
                </Grid>
                <Grid item xs={3}>
                    <Stack direction="row" spacing={1}>
                        <Box>
                            <FormControl>
                                <InputLabel id="time_start-label">c</InputLabel>
                                <Select
                                    labelId="timeout_start-label"
                                    id="timeout_start"
                                    value={props.timeout_start || '00:00'}
                                    label="c"
                                    onChange={(e) => props.changeScheduleItem('timeout_start', e.target.value)}
                                    size="small"
                                    disabled={props.disabled}
                                >
                                    {props.times.map((item, index) =>
                                    (
                                        <MenuItem value={item} key={props.name + 'timeout_start' + index}>{item}</MenuItem>
                                    )
                                    )}
                                </Select>
                            </FormControl>
                        </Box>
                        <Typography pt={1}>-</Typography>
                        <Box>
                            <FormControl>
                                <InputLabel id="time_end-label">по</InputLabel>
                                <Select
                                    labelId="timeout_end-label"
                                    id="timeout_end"
                                    value={props.timeout_end || '00:00'}
                                    label="по"
                                    onChange={(e) => props.changeScheduleItem('timeout_end', e.target.value)}
                                    size="small"
                                    disabled={props.disabled}
                                >
                                    {props.times.map((item, index) =>
                                    (
                                        <MenuItem value={item} key={props.name + 'timeout_end' + index}>{item}</MenuItem>
                                    )
                                    )}
                                </Select>
                            </FormControl>
                        </Box>
                    </Stack>
                </Grid>
                <Grid item xs={2}>
                    <IconButton aria-label="delete" size="large" onClick={(e) => props.removeScheduleItem()}>
                        <DeleteIcon />
                    </IconButton>
                </Grid>
            </Grid>
        </Box>
    )
}