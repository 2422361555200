import React, { useState, useEffect, useContext } from 'react';

import { useInsuranceSubjects, useInsuranceSubject } from "hooks/insuranceSubject/useInsuranceSubject";

import { isMobile } from 'react-device-detect';
import InsuranceSubjectsBrowserView from './BrowserView/InsuranceSubjectsBrowserView';
import InsuranceSubjectsMobileView from './MobileView/InsuranceSubjectsMobileView';
import InsuranceSubjectFormModal from './BrowserView/InsuranceSubjectFormModal';
import IsLoadingSkeleton from 'components/skeletons/IsLoadingSkeleton'

import { Context } from 'context';

export default function InsuranceSubjects() {

    const { errorLoadProgress, setTitle } = useContext(Context)

    const [filter, setFilter] = useState({})
    const [setButtonAction] = useState(null)
    const [currentId, setCurrentId] = useState(null)
    const [modalShow, setModalShow] = useState(false)
    const [setDeleteModalShow] = useState(false);
    const { data: InsuranceSubjects, isLoading: isLoadingInsObj, isError: isErrorInsObj } = useInsuranceSubjects(filter)
    const { data: InsuranceSubject } = useInsuranceSubject(currentId)

    const isLoading = isLoadingInsObj;

    const handleSave = () => {

    }

    const handleClose = () => {
        setModalShow(false);
        setDeleteModalShow(false);
    }

    const editButtonHandler = (id) => {
        setButtonAction('editButton');
        if (id === currentId) {
            setModalShow(true);
        } else {
            setCurrentId(id)
        }
    }

    const deleteButtonHandler = (id) => {
        setButtonAction('deleteButton');
        if (id === currentId) {
            setDeleteModalShow(true);
        } else {
            setCurrentId(id)
        }
    }


    useEffect(() => {
        if (isErrorInsObj) {
            errorLoadProgress('Ошибка загрузки Страховых компаний');
        }
    }, [isErrorInsObj])

    useEffect(() => {
        setTitle('Список страховых компаний');
    }, [])

    return (
        <>
            <InsuranceSubjectFormModal show={modalShow} handleClose={handleClose} handleSave={handleSave}
                InsuranceSubject={InsuranceSubject}
            />
            {
                !isLoading
                    ?
                    <>

                        {
                            isMobile
                                ? <InsuranceSubjectsMobileView subjects={InsuranceSubjects} />
                                : <InsuranceSubjectsBrowserView subjects={InsuranceSubjects} editButtonHandler={editButtonHandler} deleteButtonHandler={deleteButtonHandler} />
                        }
                    </>
                    :
                    <IsLoadingSkeleton />
            }
        </>
    )
}