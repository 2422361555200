import React from "react";
import { Avatar, Typography } from '@mui/material'


export default function AuthHeader(props) {
    const defaults = { title: 'Наименование', width: 96, height: 96, variant: 'square' }
    props = { ...defaults, ...props }

    return (
        <>
            <Avatar {...props} sx={{ width: props.width, height: props.height }}></Avatar>
            <Typography variant="h5" mt={1}>{props.title}</Typography>
        </>

    )
}