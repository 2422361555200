import React from "react"
import { Box } from "@mui/material";

export default function Home() {
    return (
        <>
            <Box>Профиль пользователя</Box>
        </>
    )
}
