import React, { useState, useEffect } from 'react';
import api from './../../helpers/api';
import { AppConfig } from './../../config';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';


import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Avatar from '@mui/material/Avatar';
import TextField from '@mui/material/TextField';
import Checkbox from '@mui/material/Checkbox';
import Input from '@mui/material/Input';

import InputLabel from '@mui/material/InputLabel';
import FormHelperText from '@mui/material/FormHelperText';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Autocomplete from '@mui/material/Autocomplete';
import Stack from '@mui/material/Stack';

import { useForm, Controller } from "react-hook-form";


export default function AddEventModal(props) {

  const defaults = { show: true }
  
  props = { ...defaults, ...props }

  const { handleSubmit, control, reset, formState: {errors, isDirty, isValid, dirtyFields } } = useForm({
    mode: 'onChange',
    defaultValues: {
      checkbox: true,
    }
  });

  const [age, setAge] = React.useState('');
  const [complaints, setComplaints] = React.useState([]);

  const handleChange = (event) => {
    setAge(event.target.value);
  };


  const handleClose = () => {
    props.handleClose(false);
  }

  const top100Films = [
    { title: 'Осмотр стоматологом', id: 1 },
    { title: 'Лечение кариеса', year: 2 },
    { title: 'Пломбирование', year: 3 },
    { title: 'Лечение воспалений', year: 4 },
    { title: 'Механическое удаление зубного камня', year: 5 },
    { title: "Травматология", year: 6 },
    { title: 'Несложные операции', year: 7 }
  ]

  useEffect(() => {
    if (props.show) {
      let config = {
        params: {
          attr_type_code: "complaint"
        },
      }
      api.get(AppConfig.apiUrl + '/attr/index', config)
        .then(response => {
          setComplaints(response.data.items);
        })
        .catch((error) => {
        });
    }

  }, [props.show])

  const onSubmit = data => console.log(data);

  return (
    <>
    <form>
        <Modal show={props.show} onHide={handleClose} {...props} >
          <Modal.Header closeButton>
            <Modal.Title>Добавление события</Modal.Title>
          </Modal.Header>
          <Modal.Body>
          

            <Box p={2}>
              <Box>
                <Grid container spacing={2} >
                  <Grid item xs={4}>
                    <Stack direction="row" justifyContent="center">
                      <Avatar
                        alt="Remy Sharp"
                        src="https://placepic.ru/wp-content/uploads/2021/05/c37d5451fc2bb9445cfbb1855aoc-kukly-i-igrushki-zubastik-critter-1.jpg"
                        sx={{ width: 150, height: 150, textAlign: 'center' }}
                        variant="rounded"
                      />
                    </Stack>
                  </Grid>
                  <Grid item xs={8}>

                  <Controller
                    name="checkbox"
                    control={control}
                    rules={{ required: true }}
                    render={({ field }) => <Checkbox {...field} />}
                  />

                 <Controller
                        name="firstname"
                        control={control}
                        rules={{ required: true }}
                        render={({ field }) => <Input {...field} />}
                      />
                 <input type="submit" />

                    <TextField label="Пациент" value="Иванов-раскоольский Петр Иванович" size="small" fullWidth />
                    <Stack mt={2}>
                      <span>тел. +7(906) 506 96 00</span>
                    </Stack>

                  </Grid>
                </Grid>
              </Box>

              <Box mt={4}>
                <Grid container spacing={2} >

                  <Grid item xs={12}>
                    <Autocomplete
                      multiple
                      id="tags-outlined"
                      size="small"
                      options={complaints}
                      getOptionLabel={(option) => option.attr_name}
                      defaultValue={[top100Films[0]]}
                      filterSelectedOptions
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Жалобы"
                          placeholder="Favorites"
                        />
                      )}
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <Autocomplete
                      multiple
                      id="tags-outlined"
                      size="small"
                      options={top100Films}
                      getOptionLabel={(option) => option.title}
                      defaultValue={[top100Films[0]]}
                      filterSelectedOptions
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Планируемые мероприятия"
                          placeholder="Favorites"
                        />
                      )}
                    />
                  </Grid>

                  <Grid item xs={4}>
                    <FormControl fullWidth>
                      <InputLabel id="demo-simple-select-label" size="small">Врач</InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        label="Врач"
                        onChange={handleChange}
                        size="small"
                      >
                        <MenuItem value={10}>Вася</MenuItem>
                        <MenuItem value={20}>Маша</MenuItem>
                        <MenuItem value={30}>Петя</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>

                  <Grid item xs={4}>
                    <TextField
                      id="datetime-local"
                      label="Время приема"
                      type="datetime-local"
                      defaultValue="2022-06-24T10:30"
                      size="small"
                      inputProps={{
                        step: 300, // 5 min
                      }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <FormControl fullWidth>
                      <InputLabel id="duration" size="small">Продолжительность</InputLabel>
                      <Select
                        labelId="duration"
                        id="duration"
                        value={60}
                        label="Продолжительность"
                        size="small"
                        onChange={handleChange}
                      >
                        <MenuItem value={30}>30 минут</MenuItem>
                        <MenuItem value={60}>1 час</MenuItem>
                        <MenuItem value={90}>1 час 30 минут</MenuItem>
                        <MenuItem value={120}>2 часа</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>

                  <Grid item xs={12}>
                    <TextField
                      id="outlined-multiline-static"
                      label="Multiline"
                      multiline
                      rows={4}
                      defaultValue=""
                      fullWidth

                    />
                  </Grid>
                </Grid>
              </Box>
            </Box>

          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Close
            </Button>
            <Button variant="primary" onClick={handleClose}>
              Save Changes
            </Button>
          </Modal.Footer>
        </Modal>
        </form>
    </>
  );
}
