import React, { useState } from "react";

import { Box, Badge, Typography } from "@mui/material";
import Stack from '@mui/material/Stack';

import Divider from '@mui/material/Divider';

export default function ClientVisit() {

    const [isVisiableVisits, setIsVisiableVisits] = useState(false);
    const handleIsVisableVisits = () => {
        setIsVisiableVisits(!isVisiableVisits)
    }

    return (
        <>
            <Divider textAlign="right">
                <Stack direction="row" onClick={handleIsVisableVisits}>

                    <Badge badgeContent={34} color="success" variant="dot">
                        <Typography variant="caption" color="">посещения</Typography>&nbsp;
                    </Badge>
                    {/* <Chip label="посещения" variant="outlined" size="small" /> */}
                </Stack>
            </Divider>
            {isVisiableVisits
                ?
                <Box>
                    <Box pl={1} pr={1} mt={1} sx={{ backgroundColor: '#f0f3ff', borderColor: '#e1e5f7', borderWidth: 1, borderStyle: 'solid' }}>
                        <Typography variant="caption" display="block">Лечение, 13.04.2022 13:30</Typography>
                        <Typography variant="caption">Врач: Петренко А.В</Typography>
                    </Box>
                    <Box pl={1} pr={1} mt={1} sx={{ backgroundColor: '#f0f3ff', borderColor: '#e1e5f7', borderWidth: 1, borderStyle: 'solid' }}>
                        <Typography variant="caption" display="block">Лечение, 13.04.2022 13:30</Typography>
                        <Typography variant="caption">Врач: Петренко А.В</Typography>
                    </Box>
                    <Box pl={1} pr={1} mt={1} sx={{ backgroundColor: '#f0f3ff', borderColor: '#e1e5f7', borderWidth: 1, borderStyle: 'solid' }}>
                        <Typography variant="caption" display="block">Лечение, 13.04.2022 13:30</Typography>
                        <Typography variant="caption">Врач: Петренко А.В</Typography>
                    </Box>
                </Box>
                :
                <></>
            }
        </>
    )
}