import React, { useState, useEffect } from 'react';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';

import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';

import Switch from '@mui/material/Switch';


export default function FilialRoomItem(props) {

    return (
        <Box pt={2} pb={2}>
            <Grid container spacing={2}>
                <Grid item xs={11}>
                    <Grid container spacing={2}>
                        <Grid item xs={3}>
                            <TextField
                                id="outlined-select-currency"
                                select
                                label="Тип помещения"
                                size="small"
                                value={props.room_type_id || 0}
                                onChange={(e) => props.changeRoomItem('room_type_id', e.target.value)}
                                fullWidth
                            >
                                {props.room_types.map((option) => (
                                    <MenuItem key={option.id} value={option.id}>
                                        {option.name}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </Grid>
                        <Grid item xs={5}>
                            <TextField
                                id="filial_room_name"
                                label="Наименование помещения"
                                size="small"
                                value={props.name || ''}
                                onChange={(e) => props.changeRoomItem('name', e.target.value)}
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={2}>
                            <TextField
                                id="filial_room_shortname"
                                label="Короткое название"
                                size="small"
                                value={props.shortname || ''}
                                onChange={(e) => props.changeRoomItem('shortname', e.target.value)}
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={2}>
                            <TextField
                                id="filial_room_number"
                                label="# комнаты"
                                size="small"
                                value={props.room_number || ''}
                                onChange={(e) => props.changeRoomItem('room_number', e.target.value)}
                                fullWidth
                            />
                        </Grid>

                        <Grid item xs={10}>
                            <TextField
                                id="filial_room_description"
                                label="Описание"
                                size="small"
                                value={props.room_description || ''}
                                onChange={(e) => props.changeRoomItem('room_description', e.target.value)}

                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={2}>
                            <TextField
                                id="filial_room_square"
                                label="Площадь, m2"
                                size="small"
                                type="number"
                                value={props.room_square || ''}
                                onChange={(e) => props.changeRoomItem('room_square', e.target.value)}
                                fullWidth
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={1}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Switch
                                checked={!!props.is_enabled}
                                inputProps={{ 'aria-label': 'controlled' }}
                                onChange={(e) => props.changeRoomItem('is_enabled',e.target.checked)}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <IconButton aria-label="delete" size="large" onClick={(e)=>props.removeRoomItem()}>
                                <DeleteIcon />
                            </IconButton>
                        </Grid>
                    </Grid>

                </Grid>
            </Grid>
        </Box>
    )
}