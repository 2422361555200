import api from 'helpers/api';
import { AppConfig } from 'config.js';
import { useQuery } from "react-query";

export const useClientCategoryies= () => {

    const { data: data, isLoading, isError } = useQuery(
        ['client_categories'],
        async () => {
            return await api.get(AppConfig.apiUrl + '/client_category');
        }
    );

    let result = [];
    data?.data.map((item, index) => {
        result.push({ value: item.id, text: item.category_name });
    });

    return { data: result, isLoading, isError}
}