import React, { useEffect, useContext } from 'react';

import { Context } from 'context';

export default function Services() {

    const {setTitle } = useContext(Context)

    useEffect(() => {
        setTitle('Услуги и цены');
    }, [setTitle])

    return (
        <>Services</>
    )
}