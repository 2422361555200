import React from "react";
import { Avatar, Box, Paper, Grid, Typography } from "@mui/material";
import Fab from '@mui/material/Fab';
import AddIcon from '@mui/icons-material/Add';


import { styled } from '@mui/system';

import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { fas } from '@fortawesome/free-solid-svg-icons'
import { far } from '@fortawesome/free-regular-svg-icons'
import { fab } from '@fortawesome/free-brands-svg-icons'

library.add(fas, far, fab);



const StyledPaper = styled(Paper)(({ theme }) => ({
    cursor: 'pointer',
    backgroundColor: theme.palette.common.main,
    transition: theme.transitions.create(['background-color', 'transform'], {
        duration: theme.transitions.duration.standard,
    }),
    '&:hover': {
        backgroundColor: theme.palette.info.main,
        transform: 'scale(1.02)',
        '.edit-btn': {
            display: 'inline-block'
        }
    }
}));


export const FilialAddItem = (props) => {

    const handleCreate = () => {
        props.handleCreate()
    }

    return (
        <StyledPaper sx={{ minWidth: '20%', height: 380 }} onClick={handleCreate}>
            <Grid
                container
                direction="column"
                alignItems="center"
                justifyContent="center"
                sx={{ height: '100%' }}
            >
                <Grid item>
                    <Box >
                        <Fab color="success" aria-label="add" >
                            <AddIcon />
                        </Fab>
                    </Box>
                </Grid>
            </Grid>
        </StyledPaper>
    )
}


export default function FilialItem(props) {

    const handleEdit = () => {
        props.handleEdit(props.id)
    }

    const handleDeleteModal = (e) => {
        props.handleDeleteModal(props.id)
        e.stopPropagation()
    }

    return (
        <StyledPaper sx={{ minWidth: '20%', height: 380 }} onClick={handleEdit}>
            <Box sx={{ position: 'relative' }}>
                <Box className="edit-btn" sx={{ display: 'none', position: 'absolute', right: 12, top: 8 }}>
                    <FontAwesomeIcon icon="fa-solid fa-trash" onClick={handleDeleteModal} />
                </Box>
            </Box>

            <Box p={2}>
                <Grid container direction="column" alignItems="center">
                    <Grid item>
                        <Box mt={2}>
                            <Avatar
                                alt="OFFICE"
                                src={props.default_image?.url}
                                sx={{ width: 140, height: 140 }}
                            />
                        </Box>
                    </Grid>
                    <Grid item>
                        <Box mt={3}>
                            <Typography variant="h6">{props.name}</Typography>
                        </Box>
                    </Grid>
                    <Grid item >
                        <Box mt={2}>
                            <Typography variant="body2" align="center">
                                <FontAwesomeIcon icon="fa-solid fa-location-dot" />
                                &nbsp;{props.address}
                            </Typography>
                        </Box>
                    </Grid>
                    <Grid item >
                        <Box mt={1}>
                            <Typography variant="body2" align="center">
                                <FontAwesomeIcon icon="fa-solid fa-phone" />
                                &nbsp;{props.phone}; {props.phone_extra}
                            </Typography>
                        </Box>
                    </Grid>

                    <Grid item>
                        <Box mt={1}>
                            <Typography>Пн-Пт 09:00 - 19:00</Typography>
                            <Typography>Сб 09:00 - 19:00</Typography>
                        </Box>
                    </Grid>
                </Grid>


            </Box>
        </StyledPaper>
    )
}