import React, { useState } from 'react';
import Box from '@mui/material/Box';

import FilialRoomItem from './FilialRoomItem';
import { Grid } from '@mui/material';


import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';

export default function FilialRooms(props) {

    const changeRoomItem = (name, value, index) => {
        props.rooms[index] = { ...props.rooms[index], ...{ [name]: value } }
        props.changeRoom(props.rooms);
    }

    const removeRoomItem = (index) => {
        props.rooms.splice(index, 1);
        props.changeRoom(props.rooms);
    }

    const addRoomItem = () => {
        let obj={}
        props.rooms.push(obj)
        props.changeRoom(props.rooms);

    }

    return (
        <>
            <Box>
                <Button variant="contained" color="success" startIcon={<AddIcon />} onClick={addRoomItem}>
                    Добавить
                </Button>
            </Box>
            <Box mt={2}>
                {props.rooms.map((item, index) => (
                    <FilialRoomItem key={'filial_rooms' + index}
                        {...item}
                        room_types={props.room_types || []}
                        changeRoomItem={(name, value) => changeRoomItem(name, value, index)}
                        removeRoomItem={() => removeRoomItem(index)}
                    />
                ))}
            </Box>
        </>
    )
}