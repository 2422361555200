import React, { useState, useEffect, useContext } from 'react';
import api from './../helpers/api';
import { AppConfig } from './../config.js';
import { Context } from '../context';
import Skeleton from '@mui/material/Skeleton';

import MenuGroup from './MenuGroup';

export default function NavBarMenu(props) {

  const { errorLoadProgress } = useContext(Context)
  const [isUploading, setisUploading] = useState(false);

  const open = props.open;
  const [menus, setMenus] = useState([]);

  const getMenus = () => {
    setisUploading(true);
    return api.get(AppConfig.apiUrl + '/menu/1', {})
      .then(response => {
        setMenus(response.data);
        setisUploading(false);
      })
      .catch((error) => {
        errorLoadProgress('Ошибка загрузки данных');
        setisUploading(false);
      });
  }

  useEffect(() => {
    getMenus();
  }, [])

  return (
    <>
      {isUploading
        ?
        <Skeleton variant="rectangular" animation="wave" sx={{ height: '100%' }} />
        :
        menus.map((item, index) => (
          <MenuGroup key={index} open={open} name={item.name} items={item.items || []}  {...props} />
        ))
      }
    </>
  )
}