import api from 'helpers/api';
import { AppConfig } from 'config.js';
import { useQuery, useMutation, useQueryClient } from "react-query";


export const useClients = (filter) => {

    const { data: data, isLoading, isFetching, isError, refetch } = useQuery(
        ['clients', filter],
        async () => {
            return await api.get(AppConfig.apiUrl + '/client', { params: filter });
        }
    );

    return { data: data?.data || [], isLoading, isFetching, isError, refetch }
}

export const useCreateClient = (id) => {
    const { data: data, isLoading, isFetching, isError, refetch, remove } = useQuery(
        ['client', id],
        async () => {
            if (id === null) {
                return {}
            }
            else if (id === 'create') {
                return await api.get(AppConfig.apiUrl + '/client/create');
            } else {
                return await api.get(AppConfig.apiUrl + '/client/' + id);
            }

        }
    );
    return { data: data?.data || [], isLoading, isFetching, isError, refetch, remove }
}


export const useClient = (id) => {
    const { data: data, isLoading, isFetching, isError, refetch, remove } = useQuery(
        ['client', id],
        async () => {
            if (id === null) {
                return {}
            }
            else if (id === 'create') {
                return await api.get(AppConfig.apiUrl + '/client/create');
            } else {
                return await api.get(AppConfig.apiUrl + '/client/' + id);
            }
        }
    );
    return { data: data?.data || [], isLoading, isFetching, isError, refetch, remove }
}

export const useSaveClient = () => {

    const queryClient = useQueryClient();

    const { isLoadingMutate, isError, mutateAsync } = useMutation(
        async (data) => {
            return await api.post(AppConfig.apiUrl + '/client', data)
        },
        {
            onSuccess: async() => {
                queryClient.invalidateQueries(['client','create'])
                queryClient.invalidateQueries(['clients'])
            }
        }
    )
    return { isLoadingMutate, isError, mutateAsync }
}

export const useDeleteClient = () => {
    const queryClient = useQueryClient();

    const { isLoadingMutate, isError, mutateAsync } = useMutation(
        'delete',
        (id) => {
            return api.delete(AppConfig.apiUrl + '/client/' + id)
        },
        {
            onSuccess: () => {
                queryClient.invalidateQueries(['clients'])
            }
        }
    )
    return { isLoadingMutate, isError, mutateAsync }
}
