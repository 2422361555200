import React from 'react';
import { Avatar, Box, Stack, Typography } from '@mui/material';


export default function EmployeeBrief(props) {

    return (
        <Stack
            direction="row"
            spacing={2}
            ml={1}
        >
            <Avatar
                src={props.src}
                alt={props.title || ''}
                sx={{ width: 64, height: 64 }}
            >
            </Avatar>
            <Box pt={0}>
                <Typography variant="h5">{props.title}</Typography>
                <Typography variant="body1">{props.employee_type_name}</Typography>
            </Box>
        </Stack >
    )
}