import React from 'react'
import 'assets/css/ScheduleBrowserView.css'

import { Grid, Box } from '@mui/material';

import FullCalendar from '@fullcalendar/react' // must go before plugins
import resourceTimelinePlugin from '@fullcalendar/resource-timeline';

import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-icons/font/bootstrap-icons.css'; // needs additional webpack config!
import bootstrap5Plugin from '@fullcalendar/bootstrap5';
import interactionPlugin from "@fullcalendar/interaction" // needed for dayClick
import RenderResourcelabelContent from './RenderResourcelabelContent';

export default function ScheduleBrowserView(props) {


    const areaWidth = 280;

    const renderResourcelabelContent = (info) => {
        console.log(info.resource.extendedProps.employee.url);
        const title = info.resource.title || '';
        const src = info.resource.extendedProps.employee.url ? info.resource.extendedProps.employee.url : '';
        const employee_type_name = info.resource.extendedProps.employee.employee_type_name ? info.resource.extendedProps.employee.employee_type_name : '';

        return (
            <RenderResourcelabelContent title={title} src={src} employee_type_name={employee_type_name}></RenderResourcelabelContent>
        )
    }

    return (
        <Box >
            <Grid container mt={2} spacing={0}>

                <FullCalendar
                    schedulerLicenseKey="CC-Attribution-NonCommercial-NoDerivatives"
                    plugins={[bootstrap5Plugin, interactionPlugin, resourceTimelinePlugin]}
                    themeSystem="bootstrap5"
                    locale={'ru'}
                    initialView='resourceTimelineDay'
                    height={'auto'}
                    firstDay={1}
                    resourceAreaWidth={areaWidth}
                    resourceAreaHeaderContent={' '}
                    /*lotLabelFormat={[
                        { month: 'long', year: 'numeric', weekday: 'short', day: 'numeric' }, // top level of text
                        { hour: 'numeric', minute: '2-digit' } // lower level of text
                    ]}*/
                    eventClick={
                        (arg) => props.eventClick(arg)
                    }

                    dayClick={
                        (arg) => props.eventClick(arg)
                    }

                    businessHours={
                        [{
                            startTime: '09:00',
                            endTime: '19:00',
                            daysOfWeek: [1, 2, 3, 4]
                        },
                        {
                            startTime: '09:00',
                            endTime: '16:00',
                            daysOfWeek: [5]
                        },
                        {
                            startTime: '10:00',
                            endTime: '15:00',
                            daysOfWeek: [6]
                        }
                        ]
                    }
                    editable={false}
                    slotMinTime="07:00"
                    slotMaxTime="21:00"

                    headerToolbar={{
                        left: 'today prev,next',
                        center: 'title',
                        right: 'resourceTimelineDay,resourceTimelineWeek,resourceTimelineMonth'
                    }}

                    resourceLabelContent={renderResourcelabelContent}
                    resources={props.resources}
                    events={props.events}

                    // resourceGroupField='room'
                    resources1={[
                        { id: 'a', room: 'Кабинет №1', title: 'Лучинский И. П', 'spec': 'Врач-хирург', 'img_url': 'https://api.ikaluga.ru/storage/sample/employee/sample_employee_1.jpg' },
                        { id: 'b', room: 'Кабинет №1', title: 'Кондратьев М. В.' },
                        { id: 'c', room: 'Кабинет №1', title: 'Мирошкина А. В.' },
                        { id: 'g', room: 'Кабинет №2', title: 'Лебедев К. А.' },
                        { id: 'h', room: 'Кабинет №2', title: 'Якимкин В. С.' },
                        { id: 'i', room: 'Кабинет №2', title: 'Пушкина Ю. П.' },
                        { id: 'j', room: 'Кабинет №2', title: 'Феоктистов С. С.' }

                    ]}

                    events1={
                        [
                            { "resourceId": "a", "title": "Рабочий день", "start": "2022-09-05 09:00", "end": "2022-09-05 13:00", "backgroundColor": '#ff0000' },
                            { "resourceId": "a", "title": "Рабочий день", "start": "2022-09-05 14:00", "end": "2022-09-05 19:00" },

                            { "resourceId": "g", "title": "Рабочий день", "start": "2022-09-05 09:00", "end": "2022-09-05 13:00" },
                            { "resourceId": "h", "title": "Рабочий день", "start": "2022-09-05 13:30", "end": "2022-09-05 15:00" },
                            { "resourceId": "i", "title": "Рабочий день", "start": "2022-09-05 15:30", "end": "2022-09-05 19:00" },
                        ]
                    }
                />
            </Grid>
        </Box>

    )
}