import React from "react";

import {
    DataGrid,
    GridToolbarContainer,
    GridToolbarQuickFilter,
    GridToolbarExport,
    ruRU
} from '@mui/x-data-grid';

import { Typography, Box, Grid } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import Avatar from '@mui/material/Avatar';
import DeleteIcon from '@mui/icons-material/Delete';
import ModeEditIcon from '@mui/icons-material/ModeEdit';

import { useTheme } from '@mui/material/styles';


function stringToColor(string: string) {
    let hash = 0;
    let i;
  
    /* eslint-disable no-bitwise */
    for (i = 0; i < string.length; i += 1) {
      hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }
  
    let color = '#';
  
    for (i = 0; i < 3; i += 1) {
      const value = (hash >> (i * 8)) & 0xff;
      color += `00${value.toString(16)}`.slice(-2);
    }
    /* eslint-enable no-bitwise */
  
    return color;
  }

function stringAvatar(name: string) {
    return {
      sx: {
        bgcolor: stringToColor(name),
      },
      children: `${name[0]}`,
    };
  }

export default function InsuranceSubjectsBrowserView(props) {

    const theme = useTheme();

    const columns = [

        {
            field: 'name',
            headerName: 'Наименование',
            description: 'This column has a value getter and is not sortable.',
            sortable: true,
            type: 'string',
            flex: 1,
            valueGetter: (params) =>
                `${params.row.name || ''} ${params.row.address || ''}`,
            renderCell: (params) => {
                return <Stack direction="row" spacing={2} ml={2}>
                    <Avatar variant="rounded" {...stringAvatar(params.row.short_name || '')} />
                    <div>
                        <Typography >{params.row.name || ''}</Typography>
                        <Typography variant="body2">{params.row.address || ''}</Typography>
                    </div>
                </Stack>
            }
        },
        {
            field: 'short_name',
            headerName: 'Алиас',
            type: 'string',
            width: 320,
            editable: false,
        },
        {
            field: 'inn',
            headerName: 'ИНН',
            type: 'string',
            width: 180,
            editable: false,
        },
        {
            field: 'ogrn',
            headerName: 'ОГРН',
            type: 'string',
            width: 180,
            editable: false,
        },
        {
            field: 'actions',
            headerName: '',
            type: 'string',
            width: 150,
            sortable: false,
            disableClickEventBubbling: true,
            renderCell: (params) => {
                return <Stack direction="row">
                    <IconButton onClick={() => { props.editButtonHandler(params.row.id) }} color="primary"><ModeEditIcon /></IconButton>
                    <IconButton onClick={() => { props.deleteButtonHandler(params.row.id) }} color="error"> <DeleteIcon /></IconButton>
                </Stack>;
            }
        }
    ]

    const CustomGridToolbar = (props) => {
        return (
            <Box p={2}>
                <GridToolbarContainer>
                    <Grid container spacing={1}>
                        <Grid item lg={3} md={4} xs={12}>
                            <GridToolbarQuickFilter size="small" sx={{ width: '100%' }} />
                        </Grid>
                        <Grid item lg={8} md={4} xs={0}>

                        </Grid>
                        <Grid item lg={1} md={4} xs={12}>
                            <GridToolbarExport csvOptions={{
                                fileName: 'customerDataBase',
                                delimiter: ';',
                                utf8WithBom: true,
                            }} />
                        </Grid>
                    </Grid>
                </GridToolbarContainer>
            </Box >
        );
    }

    return (
        <Box mt={2}>
            <DataGrid
                rows={props.subjects}
                columns={columns}
                pageSize={15}
                rowsPerPageOptions={[15]}
                disableSelectionOnClick
                disableColumnFilter
                disableColumnSelector
                disableDensitySelector
                localeText={ruRU.components.MuiDataGrid.defaultProps.localeText}
                autoHeight={true}
                components={{
                    Toolbar: CustomGridToolbar
                }}
                sx={{
                    backgroundColor: theme.palette.background.default
                }}
            />
        </Box>
    )
}