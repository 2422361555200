import React from 'react';
import { BrowserRouter as Router, useRoutes } from "react-router-dom";

import AuthWrapper from './pages/auth/AuthWrapper';
import AuthForm from './pages/auth/AuthForm';
import AuthRecoveryRequest from './pages/auth/AuthRecoveryRequest';
import AuthRecovery from './pages/auth/AuthRecovery';
import AuthRegistration from './pages/auth/AuthRegistration';
import AuthRegisterConfirmation from './pages/auth/AuthRegisterConfirmation';

import './assets/css/AppNoAuthRoutes.css';

export default function AppNoAuthRoutes(props) {

  const paperStyle = { minWidth: 320, margin: "100px auto" }

  const AppRoutes = (props) => {
    let routes = useRoutes([
      { path: "/", element: <AuthWrapper inner={<AuthForm  {...props} />} /> },
      { path: "AuthForm", element: <AuthWrapper inner={<AuthForm  {...props} />} /> },
      { path: "AuthRegistration", element: <AuthWrapper inner={<AuthRegistration {...props} />} sizes={{ xs: 12, sm: 6, md: 4 }} /> },
      { path: "AuthRegisterConfirmation", element: <AuthWrapper inner={<AuthRegisterConfirmation  {...props} />} /> },
      { path: "AuthRecoveryRequest", element: <AuthWrapper inner={<AuthRecoveryRequest  {...props} /> } /> },
      { path: "AuthRecovery", element: <AuthWrapper inner={<AuthRecovery  {...props} /> } /> },
      { path: "*", element: <AuthWrapper inner={<AuthForm  {...props} />} /> },
    ]);
    return routes;
  };

  return (
    <Router>
      <AppRoutes paperStyle={paperStyle} {...props} />
    </Router>
  )
}