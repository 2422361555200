import React, { useState, useEffect } from 'react';

import { AppConfig } from './config.js';
import { getAccessToken, clearTokens } from './helpers/api';

import Progress from './components/Progress'
import AppAuthRoutes from './AppAuthRoutes'
import AppNoAuthRoutes from './AppNoAuthRoutes';
import { SnackbarProvider, useSnackbar } from 'notistack';

function App() {

  const [isAuth, setIsAuth] = React.useState(false);
  const [isProgress, setisProgress] = useState(false);

  function handleisAuth(param) {
    setIsAuth(param);
    if (!param) {
      clearTokens();
      window.location.href = "/";
    }
  }


  const ValidAccessToken = () => {

    var myHeaders = new Headers();
    var access_token = getAccessToken();
    myHeaders.append("Accept", "application/json");
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", "Bearer " + access_token);

    var raw = JSON.stringify(
      {
        "client_id": AppConfig.client_id,
        "client_secret": AppConfig.client_secret
      }
    );

    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: raw
    };


    if (access_token !== null) {
      setisProgress(true);
      fetch(AppConfig.apiUrl + '/validAccessToken', requestOptions)
        .then(response => {
          if (!response.ok) {
            setisProgress(false);
            throw new Error("HTTP status " + response.status);
          }
          return response.json();
        })
        .then(data => {
          setIsAuth(true);
          setisProgress(false);
        })
        .catch(error => console.log('error', error));
    }

  }


  useEffect(() => { if (!isAuth) ValidAccessToken() }, []);


  return (
    <>
      <SnackbarProvider maxSnack={3}>
        {isProgress
          ?
          <Progress />
          :
          <>
            {isAuth ? <AppAuthRoutes handleisAuth={handleisAuth} isAuth={isAuth}/> : <AppNoAuthRoutes handleisAuth={handleisAuth} />}
          </>
        }
      </SnackbarProvider>
    </>
  );
}

export default App;