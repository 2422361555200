import React, { useEffect, useState, useContext } from 'react'
import api from './../../helpers/api';
import { AppConfig } from './../../config.js';
import { Context } from 'context';

import ScheduleBrowserView from './BrowserView/ScheduleBrowserView'
import ScheduleEditForm from './BrowserView/ScheduleEditForm'
import IsLoadingSkeleton from 'components/skeletons/IsLoadingSkeleton'

export default function Schedule() {

    const { isLoadProgress, handlerLoadProgress, errorLoadProgress } = useContext(Context)

    const [isOpenEditForm, setOpenEditForm] = useState(false);
    const [events, setEvents] = useState([]);
    const [resources, setResources] = useState([]);
    const [filter, setFilter] = useState([]);

    const [schedule, setSchedule] = useState([
        { id: 1, pnumber: 1, workplace_id: 2, schedule_date: '2023-07-10', time_start: '09:00', time_end: '13:00' },
        { id: 1, pnumber: 1, workplace_id: 6, schedule_date: '2023-07-10', time_start: '14:00', time_end: '19:00' },
    ]);

    const loadResources = () => {
        return api.get(AppConfig.apiUrl + '/schedule/resources', { params: filter })
            .then(response => {
                setResources(response.data);
            })
            .catch((error) => {
                errorLoadProgress('Ошибка загрузки сотрудников');
            });
    }


    const loadEvents = () => {
        return api.get(AppConfig.apiUrl + '/schedule/events', { params: filter })
            .then(response => {
                setEvents(response.data);
            })
            .catch((error) => {
                errorLoadProgress('Ошибка загрузки расписания');
            });
    }

    /** Нажатие на ячейку с расписанием */
    const eventClick = (params) => {
        setOpenEditForm(true);
        let id = params.event.id;
        console.log(id);
    }

    const saveForm = (params) => {
        setOpenEditForm(false);
    }

    const closeForm = () => {
        setOpenEditForm(false);
    }

    useEffect(() => {
        handlerLoadProgress(true);
        Promise.all([
            loadResources(),
            loadEvents()
        ]).then(() => {
            handlerLoadProgress(false);
        }).catch((error) => {
            handlerLoadProgress(false);
        });

    }, []);


    return (
        <>
            {
                !isLoadProgress ?
                    <ScheduleBrowserView resources={resources} events={events} eventClick={eventClick} />
                    : <IsLoadingSkeleton />
            }
            {isOpenEditForm
                ? <ScheduleEditForm
                   schedule={schedule}
                    isOpenEditForm={isOpenEditForm}
                    handleSave={saveForm}
                    handleClose={closeForm}
                    setSchedule={setSchedule}
                /> : <></>}
        </>

    )

}