import React from 'react';

import { Link } from "react-router-dom";
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';

import { Box, Typography, Divider, List } from '@mui/material';
import MenuItem from './MenuItem';

import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { fas } from '@fortawesome/free-solid-svg-icons'
import { far } from '@fortawesome/free-regular-svg-icons'
import { fab } from '@fortawesome/free-brands-svg-icons'

library.add(fas, far, fab);

export default function MenuGroup(props) {
    const open = props.open;
    const items = props.items || [];
    return (
        <>
            {open ?
                <Box sx={{ ml: 2, mt: 3 }}>
                    <Typography sx={{ fontWeight: 'bold' }}>{props.name || 'наименование'}</Typography>
                </Box>
                : <Divider />
            }
            <List sx={{ mt: -1 }}>
                {items.map((item, index) => (
                    <MenuItem key={index} {...props} {...item} />
                ))}
            </List>
        </>
    )
}