import React from "react";
import { styled } from '@mui/material/styles';
import { Box, TextField } from "@mui/material";
import Fab from '@mui/material/Fab';

import AddIcon from '@mui/icons-material/Add';

const StyledFab = styled(Fab)({
    position: 'fixed',
    zIndex: 1200,
    bottom: 5,
    left: 10,
    margin: '0 auto',
    backGroundColor: 'rgba(224, 224, 224)'
});

export default function ClientMobileFilter(props) {

    const searchByFilter = (e) => {
        props.searchByFilter(e.target.value)
    }
    return (
        <>
            <Box p={2}>
                <TextField
                    id="standard-helperText"
                    label="Поиск"
                    helperText="введите фразу для поиска"
                    variant="standard"
                    onChange={searchByFilter}
                    fullWidth
                />
            </Box>
            <StyledFab onClick={props.addButtonHandler} color="primary" size="small" aria-label="add">
                <AddIcon />
            </StyledFab>
        </>


    )
}