import React from "react";
import { Box, Grid, Typography, Chip, Autocomplete, TextField } from '@mui/material';


export default function CientFormAnamnesis({ allergy_list: allergyList, intolerance_list: intoleranceList, changeAnamnesis: changeAnamnesis, anamnesis: anamnesis }) {


    return (
        <Box mt={2}>
            <Grid container rowSpacing={4} spacing={2} >
                <Grid item xs={12}>
                    <Autocomplete
                        multiple
                        id="tags-allergyList"
                        options={allergyList.map((option) => option.text)}
                        value={anamnesis.allergy}
                        onChange={(event, newValue) => changeAnamnesis('allergy', newValue)}
                        freeSolo
                        renderTags={(value, getTagProps) =>
                            value.map((option, index) => (
                                <Chip variant="outlined" label={option} {...getTagProps({ index })} />
                            ))
                        }
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label="Алергия"
                                placeholder="введите значение"
                            />
                        )}
                    />
                </Grid>

                <Grid item xs={12}>
                    <Autocomplete
                        multiple
                        id="tags-intoleranceList"
                        options={intoleranceList.map((option) => option.text)}
                        value={anamnesis.intolerance}
                        onChange={(event, newValue) => changeAnamnesis('intolerance', newValue)}
                        freeSolo
                        renderTags={(value, getTagProps) =>
                            value.map((option, index) => (
                                <Chip variant="outlined" label={option} {...getTagProps({ index })} />
                            ))
                        }
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label="Непереносимость"
                                placeholder="введите значение"
                            />
                        )}
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        label="Анамнез жизни"
                        multiline
                        rows={8}
                        onChange={(e) => changeAnamnesis('anamnesis_life', e.target.value)}
                        value={anamnesis.anamnesis_life}
                        fullWidth />
                </Grid>
            </Grid>
        </Box>
    )
}