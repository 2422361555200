import React from 'react';
import { Box, Button } from '@mui/material';

import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';



const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
}));

const BootstrapDialogTitle = (props) => {
    const { children, onClose, ...other } = props;

    return (
        <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
            {children}
            {onClose ? (
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 16,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
            ) : null}
        </DialogTitle>
    );
};

const BootstrapDialogActions = (props) => {
    const { children, ...other } = props;

    return (
        <Box sx={{ m: 0, p: 1 }} >
            <DialogActions {...other}>
                {children}
            </DialogActions>
        </Box>
    );
};

BootstrapDialogTitle.propTypes = {
    children: PropTypes.node,
    onClose: PropTypes.func.isRequired,
};

BootstrapDialogActions.propTypes = {
    children: PropTypes.node
};

export default function (props) {
    const { onSave, ...other } = props;
    return (
        <BootstrapDialog {...other}>
            <BootstrapDialogTitle id="customized-dialog-title" onClose={props.onClose}>
                {props.title || ''}
            </BootstrapDialogTitle>
            <DialogContent dividers>
                {props.children}
            </DialogContent>
            <BootstrapDialogActions>
                <Button variant="secondary" onClick={props.onClose} >
                    Закрыть
                </Button>
                <Button variant="success" onClick={onSave} disabled={false}>
                    Сохранить
                </Button>
            </BootstrapDialogActions>
        </BootstrapDialog>
    )
}
