import React, { useState } from 'react'

import { Box, Button, Grid, Paper, TextField } from '@mui/material';

import SendIcon from '@mui/icons-material/Send';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';

import FormSelect from 'components/custom/inputs/FormSelect';


export default function ClientBrowserFilter(props) {


    const addButtonHandler = () => {
        props.addButtonHandler()
    }

    const filterButtonHandler = () => {
        props.filterButtonHandler()
    }

    return (
        <Paper elevation={0} variant="outlined">
            <Box p={2}>
                <Grid container spacing={1} justify="center" alignItems="center">
                    <Grid item lg={2} md={2} xs={12}>
                        <FormSelect
                            label="Категория"
                            size="small"
                            value={props.filter?.client_category_id || ''}
                            items={props.client_categories}
                            onChange={(e) => props.changeFilter('client_category_id', e.target.value)} />
                    </Grid>
                    <Grid item lg={2} md={2} xs={12}>
                        <LocalizationProvider dateAdapter={AdapterDateFns} >
                            <DesktopDatePicker
                                label="Дата рождения c"
                                inputFormat="dd.MM.yyyy"
                                value={props.filter?.birthday_from || null}
                                onChange={(value) => props.changeFilter('birthday_from', value)}
                                renderInput={(params) => <TextField {...params} size="small" fullWidth />}
                            />
                        </LocalizationProvider>
                    </Grid>
                    <Grid item lg={2} md={2} xs={12}>
                        <LocalizationProvider dateAdapter={AdapterDateFns} >
                            <LocalizationProvider dateAdapter={AdapterDateFns} >
                                <DesktopDatePicker
                                    label="Дата рождения по"
                                    inputFormat="dd.MM.yyyy"
                                    value={props.filter?.birthday_to || null}
                                    onChange={(value) => props.changeFilter('birthday_to', value)}
                                    renderInput={(params) => <TextField {...params} size="small" fullWidth />}
                                />
                            </LocalizationProvider>
                        </LocalizationProvider>
                    </Grid>
                    <Grid item lg={1} md={2} xs={12}>
                        <Button variant="contained" color="success" onClick={filterButtonHandler} endIcon={<SendIcon />} fullWidth>Поиск</Button>
                    </Grid>
                    <Grid item lg={4} md={2} xs={0}>
                    </Grid>
                    <Grid item lg={1} md={2} xs={12} style={{ textAlign: "right" }}>
                        <Button variant="contained" onClick={addButtonHandler} fullWidth>Добавить</Button>
                    </Grid>
                </Grid>
            </Box>
        </Paper>
    )
}