import React, { useState, useEffect } from 'react';
import api from 'helpers/api';
import { AppConfig } from 'config.js';
import { Box, Button, Container, Drawer, Grid, Typography } from '@mui/material';
import ScheduleItem from './ScheduleEditForm/ScheduleItem';
import AddIcon from '@mui/icons-material/Add';
import BootstrapDialog from 'components/dialogs/BootstrapDialog.jsx'
import EmployeeBrief from 'components/employee/EmployeeBrief';

export default function ScheduleEditForm(props) {

    const places = [
        { id: 1, parent: 1, 'name': 'Кабинет стоматолога #1, Ф1' },
        { id: 2, parent: 1, 'name': 'Кабинет стоматолога #2, Ф1' },
        { id: 3, parent: 1, 'name': 'Кабинет стоматолога #3, Ф1' },
        { id: 4, parent: 1, 'name': 'Кабинет стоматолога #4, Ф1' },
        { id: 5, parent: 2, 'name': 'Кабинет стоматолога #4, Ф2' },
        { id: 6, parent: 2, 'name': 'Кабинет стоматолога #4, Ф2' },
        { id: 7, parent: 3, 'name': 'Кабинет стоматолога #4, Ф3' },
        { id: 8, parent: 3, 'name': 'Кабинет стоматолога #4, Ф3' },
        { id: 9, parent: 3, 'name': 'Кабинет стоматолога #4, Ф3' }
    ]

    const times = [
        '00:00', '00:15', '00:30', '00:45', '01:00', '01:15', '01:30', '01:45', '02:00', '02:15', '02:30', '02:45',
        '03:00', '03:15', '03:30', '03:45', '04:00', '04:15', '04:30', '04:45', '05:00', '05:15', '05:30', '05:45',
        '06:00', '06:15', '06:30', '06:45', '07:00', '07:15', '07:30', '07:45', '08:00', '08:15', '08:30', '08:45',
        '09:00', '09:15', '09:30', '09:45', '10:00', '10:15', '10:30', '10:45', '11:00', '11:15', '11:30', '11:45',
        '12:00', '12:15', '12:30', '12:45', '13:00', '13:15', '13:30', '13:45', '14:00', '14:15', '14:30', '14:45',
        '15:00', '15:15', '15:30', '15:45', '16:00', '16:15', '16:30', '16:45', '17:00', '17:15', '17:30', '17:45',
        '18:00', '18:15', '18:30', '18:45', '19:00', '19:15', '19:30', '19:45', '20:00', '20:15', '20:30', '20:45',
        '21:00', '21:15', '21:30', '21:45', '22:00', '22:15', '22:30', '22:45', '23:00', '23:15', '23:30', '23:45',
    ]


    const changeScheduleItem = (name, value, index) => {
        let schedule_ = JSON.parse(JSON.stringify(props.schedule));
        schedule_[index] = { ...schedule_[index], ...{ [name]: value } }
        props.setSchedule(schedule_)
    }

    const addScheduleItem = () => {
        let obj = {}
        let schedule_ = JSON.parse(JSON.stringify(props.schedule));
        schedule_.push(obj)
        props.setSchedule(schedule_)
    }

    const removeScheduleItem = (index) => {
        let schedule_ = JSON.parse(JSON.stringify(props.schedule));
        schedule_.splice(index, 1)
        props.setSchedule(schedule_)
    }

    return (
        <BootstrapDialog
            open={props.isOpenEditForm}
            onClose={props.handleClose}
            onSave={props.handleSave}
            maxWidth="md"
            fullWidth={true}
            disableScrollLock={true}
            title={!props.id ? 'Создание расписания' : 'Редактирование расписания'}
        >
            <Container padding={2}>
                <Box mt={2}>
                    <EmployeeBrief
                        title="Якимкин В. С"
                        employee_type_name="Врач"
                        src="http://api.ikaluga.local/storage/sample/employee/sample_employee_5.jpg"
                    >
                    </EmployeeBrief>
                </Box>
                <Box mt={4}>
                    <Box>
                        <Grid container spacing={2}>
                            <Grid item xs={4}>
                                <Typography pt={1}>
                                    Рабочее место:
                                </Typography>
                            </Grid>
                            <Grid item xs={3}>
                                <Typography pt={1}>
                                    Рабочее время:
                                </Typography>
                            </Grid>
                            <Grid item xs={3}>
                                <Typography pt={1}>
                                    Перерыв:
                                </Typography>
                            </Grid>
                            <Grid item xs={2}>
                                <Button variant="outlined" color="success" startIcon={<AddIcon />} onClick={addScheduleItem} size="small">
                                    Добавить
                                </Button>
                            </Grid>
                        </Grid>
                    </Box>
                    {props?.schedule.map((item, index) => (
                        <ScheduleItem
                            key={'ScheduleItem_' + index}
                            {...item}
                            places={places}
                            times={times}
                            changeScheduleItem={(name, value) => changeScheduleItem(name, value, index)}
                            removeScheduleItem={() => removeScheduleItem(index)}
                        ></ScheduleItem>
                    ))}
                </Box>
            </Container>
        </BootstrapDialog>
    )
}