import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Stack from '@mui/material/Stack';
import Chip from '@mui/material/Chip';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { Checkbox, FormControlLabel, List, ListItem, ListItemIcon, ListItemText } from '@mui/material';

import FilialRooms from './BrowserView/FilialFormModal/FilialRooms';
import FilialImages from './BrowserView/FilialFormModal/FilialImages';



const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
}));

const BootstrapDialogTitle = (props) => {

    const { children, onClose, ...other } = props;

    return (
        <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
            {children}
            {onClose ? (
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
            ) : null}
        </DialogTitle>
    );
};

BootstrapDialogTitle.propTypes = {
    children: PropTypes.node,
    onClose: PropTypes.func.isRequired,
};


export default function FilialFormModal(props) {

    const [filial, setFilial] = useState({})
    const [valueTab, setValueTab] = useState('1');


    const changeFilial = (name, value) => {
        setFilial({ ...filial, ...{ [name]: value } });
    }

    const handleTabChange = (event, newValue) => {
        setValueTab(newValue);
    };

    const changeDay = (row, day_number) => {
        let schedule = { ...filial.schedule, ...{ [day_number]: row } }
        changeFilial('schedule', schedule)
    }
    const changeRoom = (rooms) => {
        changeFilial('rooms', rooms)

    }

    const handleSave = () => {
        props.handleSave(filial);
    }

    const handleClose = () => {
        props.handleClose(filial);
    }

    const handleChangeImages = () => {
        props.handleUpdateFilials()
    }

    const DayItem = (props) => {

        const [checked, setChecked] = useState(true);
        const [isNew, setisNew] = useState(false);
        const [isRoundTheClock, setIsRoundTheClock] = useState(false);

        const handleChange = (event) => {
            setChecked(event.target.checked);
            if (event.target.checked) {
                props.schedule[0] = { time_start: '09:00', time_end: '18:00', timeout_start: '00:00', timeout_end: '00:00', is_around_the_clock: false }
                props.changeDay(props.schedule);
            }
        }

        const handleChangeRound = () => {
            let newVal = !isRoundTheClock;
            setIsRoundTheClock(newVal)
            props.schedule.length = 0
            props.schedule[0] = { time_start: '00:00', time_end: '00:00', timeout_start: '00:00', timeout_end: '00:00', is_around_the_clock: +newVal }
            props.changeDay(props.schedule);
        }

        const changeTime = (name, value, index) => {
            props.schedule[index] = { ...props.schedule[index], ...{ [name]: value, is_around_the_clock: 0 } }
            props.changeDay(props.schedule);
        }

        useEffect(() => {
            setChecked(props.schedule && Object.keys(props.schedule).length > 0)
            setisNew(props.schedule && Object.keys(props.schedule).length === 0)
            setIsRoundTheClock(props.schedule && props.schedule[0] && props.schedule[0].is_around_the_clock === 1)

        }, [props.schedule])

        return (
            <>
                <Box>
                    <Box mb={0}>
                        <FormControlLabel control={<Checkbox checked={checked} onChange={handleChange} />} label={props.name} />
                        <Chip color={isRoundTheClock ? 'success' : 'default'} size="small" label="круглосуточно" onClick={handleChangeRound} />
                    </Box>
                    <Box mt={1}>
                        {!isNew
                            ?
                            props.schedule.map((item, index) => (
                                <DayRowItem {...item} key={'DayRowItem_' + index} changeTime={(name, value) => changeTime(name, value, index)} disabled={!checked} />
                            ))
                            : <DayRowItem changeTime={changeTime} disabled={!checked} />
                        }
                    </Box>
                </Box >
                <Divider />
            </>
        )
    }


    const DayRowItem = (props) => {

        const changeTime = (name, value) => {
            props.changeTime(name, value);
        }

        const times = [
            '00:00', '00:15', '00:30', '00:45', '01:00', '01:15', '01:30', '01:45', '02:00', '02:15', '02:30', '02:45',
            '03:00', '03:15', '03:30', '03:45', '04:00', '04:15', '04:30', '04:45', '05:00', '05:15', '05:30', '05:45',
            '06:00', '06:15', '06:30', '06:45', '07:00', '07:15', '07:30', '07:45', '08:00', '08:15', '08:30', '08:45',
            '09:00', '09:15', '09:30', '09:45', '10:00', '10:15', '10:30', '10:45', '11:00', '11:15', '11:30', '11:45',
            '12:00', '12:15', '12:30', '12:45', '13:00', '13:15', '13:30', '13:45', '14:00', '14:15', '14:30', '14:45',
            '15:00', '15:15', '15:30', '15:45', '16:00', '16:15', '16:30', '16:45', '17:00', '17:15', '17:30', '17:45',
            '18:00', '18:15', '18:30', '18:45', '19:00', '19:15', '19:30', '19:45', '20:00', '20:15', '20:30', '20:45',
            '21:00', '21:15', '21:30', '21:45', '22:00', '22:15', '22:30', '22:45', '23:00', '23:15', '23:30', '23:45',
        ]

        return (
            <Box mb={2}>
                <Grid container justifyContent="center">
                    <Grid item xs={6}>
                        <Stack direction="row" spacing={2} justifyContent="center">
                            <Typography pt={1}>
                                Рабочее время:
                            </Typography>
                            <Box>
                                <FormControl>
                                    <InputLabel id="time_start-label">c</InputLabel>
                                    <Select
                                        labelId="time_start-label"
                                        id="time_start"
                                        value={props.time_start || '00:00'}
                                        label="с"
                                        onChange={(e) => changeTime('time_start', e.target.value)}
                                        size="small"
                                        disabled={props.disabled}
                                    >
                                        {times.map((item, index) =>
                                        (
                                            <MenuItem value={item} key={props.name + 'time_start' + index} >{item}</MenuItem>
                                        )
                                        )}
                                    </Select>
                                </FormControl>
                            </Box>
                            <Typography pt={1}>-</Typography>
                            <Box>
                                <FormControl>
                                    <InputLabel id="time_end-label">по</InputLabel>
                                    <Select
                                        labelId="time_end-label"
                                        id="time_end"
                                        value={props.time_end || '00:00'}
                                        label="по"
                                        onChange={(e) => changeTime('time_end', e.target.value)}
                                        size="small"
                                        disabled={props.disabled}
                                    >
                                        {times.map((item, index) =>
                                        (
                                            <MenuItem value={item} key={props.name + 'time_end' + index}>{item}</MenuItem>
                                        )
                                        )}
                                    </Select>
                                </FormControl>
                            </Box>
                        </Stack>
                    </Grid>
                    <Grid item xs={6}>
                        <Stack direction="row" spacing={2}>
                            <Typography pt={1}>
                                Перерыв:
                            </Typography>
                            <Box>
                                <FormControl>
                                    <InputLabel id="time_start-label">c</InputLabel>
                                    <Select
                                        labelId="timeout_start-label"
                                        id="timeout_start"
                                        value={props.timeout_start || '00:00'}
                                        label="c"
                                        onChange={(e) => changeTime('timeout_start', e.target.value)}
                                        size="small"
                                        disabled={props.disabled}
                                    >
                                        {times.map((item, index) =>
                                        (
                                            <MenuItem value={item} key={props.name + 'timeout_start' + index}>{item}</MenuItem>
                                        )
                                        )}
                                    </Select>
                                </FormControl>
                            </Box>
                            <Typography pt={1}>-</Typography>
                            <Box>
                                <FormControl>
                                    <InputLabel id="time_end-label">по</InputLabel>
                                    <Select
                                        labelId="timeout_end-label"
                                        id="timeout_end"
                                        value={props.timeout_end || '00:00'}
                                        label="по"
                                        onChange={(e) => changeTime('timeout_end', e.target.value)}
                                        size="small"
                                        disabled={props.disabled}
                                    >
                                        {times.map((item, index) =>
                                        (
                                            <MenuItem value={item} key={props.name + 'timeout_end' + index}>{item}</MenuItem>
                                        )
                                        )}
                                    </Select>
                                </FormControl>
                            </Box>
                        </Stack>
                    </Grid>
                </Grid>
            </Box>
        )
    }

    useEffect(() => {
        let filial = { ...props.filial }
        setFilial(filial)
    }, [props.filial]);


    return (
        <>
            <BootstrapDialog open={props.show} onClose={handleClose} maxWidth="lg" fullWidth={true} scroll="paper"
                PaperProps={{ sx: { minHeight: '80vh', maxHeight: '80vh' } }}
            >

                <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose}>
                    Редактирование филиала
                </BootstrapDialogTitle>
                <DialogContent dividers>
                    <TabContext value={valueTab}>
                        <Box>
                            <TabList onChange={handleTabChange}
                                aria-label="Информация о филиале"
                                variant="scrollable"
                                scrollButtons
                                allowScrollButtonsMobile
                            >
                                <Tab label="Основная информация" value="1" />
                                <Tab label="График работы" value="2" />
                                <Tab label="Помещения" value="3" />

                            </TabList>
                        </Box>
                        <TabPanel value="1">
                            <Box>
                                <Grid container spacing={2}>
                                    <Grid item xs={6}>
                                        <TextField label="Наименование" value={filial.name || ''} onChange={(e) => changeFilial('name', e.target.value)} size="small" fullWidth />
                                    </Grid>
                                    <Grid item xs={3}>
                                        <TextField label="Телефон" value={filial.phone || ''} onChange={(e) => changeFilial('phone', e.target.value)} size="small" fullWidth />
                                    </Grid>
                                    <Grid item xs={3}>
                                        <TextField label="Доп. телефон" value={filial.phone_extra || ''} onChange={(e) => changeFilial('phone_extra', e.target.value)} size="small" fullWidth />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField label="Адрес" value={filial.address || ''} onChange={(e) => changeFilial('address', e.target.value)} size="small" fullWidth />
                                    </Grid>
                                </Grid>
                            </Box>

                            <Box>
                                <Typography mt={3} variant="overline" display="block" gutterBottom >Изображения</Typography>
                                <FilialImages files={filial.images || []} object_id={filial.id} object="App\Models\Filial\Filial" onChange={handleChangeImages} />
                            </Box>

                        </TabPanel>
                        <TabPanel value="2">
                            <Box>
                                <Grid container spacing={2}>
                                    <Grid item xs={12}>
                                        <Stack direction="column" justifyContent="space-around" spacing={1}>
                                            {
                                                props.week_days.map((item, index) => (
                                                    <DayItem {...item} key={'DayItem_' + index} changeDay={(rows) => changeDay(rows, item.number)} schedule={filial?.schedule?.[item.number] || []} />
                                                ))
                                            }
                                        </Stack>
                                    </Grid>
                                </Grid>
                            </Box>
                        </TabPanel>
                        <TabPanel value="3">
                            <FilialRooms rooms={filial.rooms || []} room_types={props.room_types} changeRoom={changeRoom} />
                        </TabPanel>
                    </TabContext>

                </DialogContent>
                <DialogActions>
                    <Button variant="outlined" onClick={handleClose}>
                        Закрыть
                    </Button>
                    <Button variant="contained" color="success" onClick={handleSave}>
                        Сохранить
                    </Button>
                </DialogActions>
            </BootstrapDialog>
        </>
    )
}