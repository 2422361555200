import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';

export default function FilialDeleteFormModal(props) {

    const BootstrapDialog = styled(Dialog)(({ theme }) => ({
        '& .MuiDialogContent-root': {
            padding: theme.spacing(2),
        },
        '& .MuiDialogActions-root': {
            padding: theme.spacing(1),
        },
    }));

    const BootstrapDialogTitle = (props) => {

        const { children, onClose, ...other } = props;

        return (
            <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
                {children}
                {onClose ? (
                    <IconButton
                        aria-label="close"
                        onClick={onClose}
                        sx={{
                            position: 'absolute',
                            right: 8,
                            top: 8,
                            color: (theme) => theme.palette.grey[500],
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                ) : null}
            </DialogTitle>
        );
    };

    BootstrapDialogTitle.propTypes = {
        children: PropTypes.node,
        onClose: PropTypes.func.isRequired,
    };

    const handleClose = () => {
        props.handleClose(props.filial);
    }

    const handleDelete = () => {
        props.handleDelete(props.filial);
    }

    return (
        <>
            <BootstrapDialog open={props.show} onClose={handleClose} maxWidth="sm" scroll="paper"
            >
                <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose}>
                    Удаление филиала
                </BootstrapDialogTitle>
                <DialogContent dividers>
                    <Box sx={{ textAlign: 'center' }} mt={2}>
                        <Typography>Удалить филиал <b>{props.filial.name}</b>?</Typography>
                        <Typography variant="caption">Востановление возможно, через системного администратора</Typography>
                    </Box>
                </DialogContent>
                <DialogActions>
      
                    <Button variant="contained" color="error" onClick={handleDelete}>
                        Удалить
                    </Button>
                </DialogActions>
            </BootstrapDialog>
        </>
    )
}
