import React, { useState, useEffect } from 'react';

import { Grid } from '@mui/material'

import FormControl from "@mui/material/FormControl";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import InputAdornment from "@mui/material/InputAdornment";
import FormHelperText from '@mui/material/FormHelperText';
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import IconButton from "@mui/material/IconButton";

export default function PasswordWithConfirm(props) {

    const defaults = { name: 'password', item: false, xs: 12, rowSpacing: 1, columnSpacing: { xs: 1, sm: 2, md: 3 } };
    props = { ...defaults, ...props };


    const [password, setPassword] = useState('');
    const [c_password, setCPassword] = useState('');
    const [isPassSame, setIsPassSame] = useState(false);
    const [isWeak, setIsWeak] = useState(true);
    const [isValid, setIsValid] = useState(false);
    const [showPassword, setshowPassword] = useState(false);
    const [TextHelper, setTextHelper] = useState('');

    const handleClickShowPassword = () => {
        setshowPassword(!showPassword);
    };

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };


    const handleIsPassSame = async () => {
        let isPassSame = password.length === 0 || password === c_password;
        setIsPassSame(isPassSame);
        return isPassSame;
    }

    const handleIsWeakPassword = async () => {
        setIsWeak(!(password.length >= 8));
        return isWeak;
    }

    const handleTextHelper = () => {
        let TextHelper='';
        if (!isPassSame) {
            TextHelper = 'пароли не совпадают';
        } else if(isPassSame && isWeak){
            TextHelper = 'слабый пароль';
        }else {
            TextHelper = 'отличный пароль';
        }
        setTextHelper(TextHelper);
    }

    const handleIsValid = async () => {
        let isPassSame = await handleIsPassSame();
        let isWeak = await handleIsWeakPassword();

        let isValid = !isWeak && isPassSame;
        setIsValid(isValid);
        props.onChange({ target: { name: props.name, value: password, isValid: isValid } });
        return isValid;
    }

    
    useEffect(() => {
        handleTextHelper();
    }, [isWeak, isPassSame])

    useEffect(() => {
        handleIsValid().then(()=>{
            handleTextHelper();
        })
        
    }, [password, c_password])

    return (
        <>
            <Grid container rowSpacing={props.rowSpacing} columnSpacing={props.columnSpacing}>
                <Grid item={props.item} xs={6}>
                    <FormControl sx={{ mt: 3 }} variant="outlined" fullWidth size="small" error={!isValid}>
                        <InputLabel htmlFor="register_password">
                            Пароль
                        </InputLabel>
                        <OutlinedInput
                            id="register_password"
                            type={showPassword ? "text" : "password"}
                            value={password}
                            onChange={(e) => { setPassword(e.target.value); }}
                            endAdornment={
                                <InputAdornment position="end">
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={handleClickShowPassword}
                                        onMouseDown={handleMouseDownPassword}
                                        edge="end"
                                    >
                                        {showPassword ? <VisibilityOff /> : <Visibility />}
                                    </IconButton>
                                </InputAdornment>
                            }
                            label="Пароль"
                        />
                        <FormHelperText id="outlined-helper-text">{TextHelper}</FormHelperText>
                    </FormControl>
                </Grid>

                <Grid item={props.item} xs={6}>
                    <FormControl sx={{ mt: 3 }} variant="outlined" fullWidth size="small" error={!isValid} >
                        <InputLabel htmlFor="register_c_password" >
                            Повторите пароль
                        </InputLabel>
                        <OutlinedInput
                            id="register_c_password"
                            type={showPassword ? "text" : "password"}
                            value={c_password}
                            onChange={(e) => { setCPassword(e.target.value); }}
                            endAdornment={
                                <InputAdornment position="end">
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={handleClickShowPassword}
                                        onMouseDown={handleMouseDownPassword}
                                        edge="end"
                                    >
                                        {showPassword ? <VisibilityOff /> : <Visibility />}
                                    </IconButton>
                                </InputAdornment>
                            }
                            label="Повторите пароль"
                        />
                        <FormHelperText id="outlined-helper-text">{TextHelper}</FormHelperText>
                    </FormControl>
                </Grid>
            </Grid>
        </>
    )
}