import React from 'react';
import PropTypes from 'prop-types';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import PriorityHighTwoToneIcon from '@mui/icons-material/PriorityHighTwoTone';
import { IMaskInput } from 'react-imask';


const TextMaskCustom = React.forwardRef(function TextMaskCustom(props, ref) {
  const { onChange, ...other } = props;
  return (
    <IMaskInput
      {...other}
      mask="+7(#00) 000-0000"
      definitions={{
        '#': /[1-9]/,
      }}
      lazy={false}
      inputRef={ref}
      onAccept={(value) => onChange({ target: { name: props.name, value } })}
      overwrite
    />
  );
});

TextMaskCustom.propTypes = {
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default function InputPhoneField(props) {

  const defaults = { label: 'Номер телефона', name: 'text-mask-input', size: 'small', lazy: false };
  props = { ...defaults, ...props };

  const [value, setValue] = React.useState('')
  const [isDirtyField, setIsDirtyField] = React.useState(false)
  const [isValid, setIsValid] = React.useState(false)

  const handleChange = event => {
    setValue(event.target.value);
    event.target.isValid = handleValid(event.target.value);
    props.onChange(event)
  }

  const handleValid = (val) => {
    let count = 0;
    for (let i = 0; i < val.length; i++) {
      if (val.charAt(i) in ['+', 0, 1, 2, 3, 4, 5, 6, 7, 8, 9]) count++
    }
    let result = count === 11;
    setIsValid(result);
    return result;
  }

  React.useEffect(() => {
    setValue(props.value);
  }, [props]);

  return (
    <FormControl variant="outlined" fullWidth size={props.size} >
      <InputLabel >{props.label}</InputLabel>
      <OutlinedInput
        helpertext={props.helperText}
        error={isDirtyField && !isValid}
        onChange={handleChange}
        onBlur={() => setIsDirtyField(true)}
        label={props.label}
        name={props.name}
        value={value}
        inputComponent={TextMaskCustom}
        endAdornment={!isValid ?
          <InputAdornment position="end">
            <IconButton
              aria-label="toggle password visibility"
              edge="end"
            >
              <PriorityHighTwoToneIcon />
            </IconButton>
          </InputAdornment>
          :
          <></>
        }
      />
    </FormControl>
  );
}