import React, { useState } from "react";
import api from 'helpers/api';
import { AppConfig } from 'config.js';

import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Avatar from '@mui/material/Avatar';
import Skeleton from '@mui/material/Skeleton';

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import DeleteIcon from '@mui/icons-material/Delete';
import IconButton from '@mui/material/IconButton';


export default function File(props) {

    const defaults = { alt: 'Изображение', 'single': true, 'object': 'image', 'object_type': 'image', width: 150, height: 150, textAlign: 'center', variant: 'rounded' };
    props = { ...defaults, ...props };

    const [isUploading, setisUploading] = useState(false);
    const [openDelConfirm, setOpenDelConfirm] = React.useState(false);

    const delBtnStyle = {
        position: 'absolute',
        top: '3px',
        right: '3px',
        backgroundColor: '#fb7272',
        width: '32px',
        height: '32px',
        textAlign: 'center',
        fontSize: '24px'
    }

    const selectFile = (files) => {
        let params = {
            file: files[0],
            'object_id': props.object_id || 0,
            'object': props.object,
            'object_type': props.object_type,
            'single': props.single
        }

        setisUploading(true);
        api.post(AppConfig.apiUrl + '/file/upload', params, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        }).then(response => {
            props.onChange(response.data.result.url);
            setisUploading(false);
        }).catch((error) => {
            setisUploading(false);
            console.log('error', error);
        });
    }

    const handleOpenDelConfirm = (e) => {
        e.preventDefault();
        setOpenDelConfirm(true);
    };

    const handleCloseDelNotConfirm = () => {
        setOpenDelConfirm(false);
    }

    const handleCloseDelConfirm = () => {
        let params = {
            'url': props.value
        }

        api.post(AppConfig.apiUrl + '/file/delete', params, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        }).then(response => {
            props.onChange('');
            setisUploading(false);
        }).catch((error) => {
            setisUploading(false);
            console.log('error', error);
        });

        setOpenDelConfirm(false);
    };


    return (
        <Stack direction="row" justifyContent="center">
            <input hidden id="contained-button-file" accept="image/*" type="file" onChange={(e) => selectFile(e.target.files)} />
            <label htmlFor="contained-button-file">
                {!isUploading ? (<Box sx={{ position: 'relative' }}>
                    <Avatar
                        alt={props.alt}
                        src={props.value}
                        sx={{ width: props.width, height: props.height, textAlign: props.textAlign }}
                        variant={props.variant}
                    />
                    {props.value != '' ?
                        <IconButton sx={delBtnStyle} onClick={(e) => handleOpenDelConfirm(e)}>
                            <DeleteIcon />
                        </IconButton>
                        :
                        <></>}

                    <Dialog
                        open={openDelConfirm}
                        onClose={handleCloseDelConfirm}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                    >
                        <DialogTitle id="alert-dialog-title">
                            Подтверждение удаления
                        </DialogTitle>
                        <DialogContent>
                            <DialogContentText id="alert-dialog-description">
                                Вы действительно хотите удалить изображение?
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={handleCloseDelNotConfirm}>Отмена</Button>
                            <Button onClick={handleCloseDelConfirm} autoFocus>
                                Удалить
                            </Button>
                        </DialogActions>
                    </Dialog>

                </Box>) : (
                    <Skeleton variant="rectangular" width={props.width} height={props.height} />
                )
                }

            </label>
        </Stack>
    );
}