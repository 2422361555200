import React, { useState, useEffect } from 'react';
import { AppConfig } from 'config.js';
import ReCAPTCHA from "react-google-recaptcha";
import { Grid, Box, Paper, TextField, Typography, Link } from '@mui/material'
import LoadingButton from '@mui/lab/LoadingButton';

import AuthHeader from "pages/auth/AuthHeader"
import InputPhoneField from 'components/custom/inputs/InputPhoneField'
import InputEmailField from 'components/custom/inputs/InputEmailField'
import PasswordWithConfirm from 'components/custom/PasswordWithConfirm'

import icon from 'assets/icons/agille-working.png';

import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';

export default function AuthRegistration(props) {


    const [lastname, setLastName] = useState('');
    const [firstname, setFirstName] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [password, setPassword] = useState('');
    const [isProgress, setisProgress] = useState(false);
    const [isRegistred, setisRegistred] = useState(false);

    const [isVerifed, setisVerifed] = useState(false);
    const [VerifedFields, setVerifedFields] = useState({
        lastname: false,
        firstname: false,
        email: false,
        phone: false,
        password: false,
        captcha: false
    });

    const [responseError, setResponseError] = useState({
        email: { state: false, text: '' }
    });

    const handleVerifedFields = (e) => {
        let name = e.target.name;
        let value = e.target.value;
        let result = false;

        switch (name) {
            case 'lastname':
            case 'firstname':
                result = value.length >= 3
                break;
            case 'email':
            case 'phone':
            case 'password':
                result = e.target.isValid || false;
                break;
            default:
                result = false;

        }
        setVerifedFields({ ...VerifedFields, ...{ [name]: result } });
    }


    const verifyReCaptcha = (value) => {
        setVerifedFields({ ...VerifedFields, ...{ 'captcha': true } });
    }


    const sendAuthRegistrationRequest = () => {

        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        var oauth_url = AppConfig.apiUrl + '/user/registration/request';
        var raw = JSON.stringify(
            {
                "lastname": lastname,
                "firstname": firstname,
                "email": email,
                "phone": phone,
                "password": password,
                "callback": AppConfig.appUrl + "/AuthRegisterConfirmation",
                "client_id": AppConfig.client_id,
                "client_secret": AppConfig.client_secret
            }
        );

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw
        };

        setisProgress(true);
        fetch(oauth_url, requestOptions)
            .then(response => response.json())
            .then(result => {
                if (result.status === false) {
                    setResponseError(result.data);
                } else {
                    setisRegistred(true);
                }
                setisProgress(false);
            })
            .catch(error => console.log('error', error));
    }


    useEffect(() => {
        const allTrue = (obj) => {
            for (var o in obj)
                if (!obj[o]) return false;
            return true;
        }
        setisVerifed(allTrue(VerifedFields) || false);
    }, [VerifedFields])

    return (
            <Paper elevation={10} style={props.paperStyle}>
                <Box p={3} >
                    <Grid align='center'>
                        <Box>
                            <AuthHeader src={icon} title='Регистрация пользователя' />
                        </Box>
                    </Grid>
                    {!isRegistred
                        ?
                        <>
                            <Box component="form" sx={{ "& .MuiTextField-root": { mt: 2 } }} noValidate >
                                <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                                    <Grid item xs={6}>
                                        <TextField label='Фамилия' size='small' name='lastname' onChange={(e) => { setLastName(e.target.value); handleVerifedFields(e) }} placeholder='Ваша фамилия' error={!VerifedFields.lastname} fullWidth />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <TextField label='Имя' size="small" name='firstname' onChange={(e) => { setFirstName(e.target.value); handleVerifedFields(e) }} placeholder='Ваше имя' error={!VerifedFields.firstname} fullWidth />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Box sx={{ '& > :not(style)': { mt: 3 }, }} noValidate autoComplete="off">
                                            <InputEmailField label='Email' size="small" name='email' value={email} wrong={responseError.email} onChange={(e) => { setEmail(e.target.value); handleVerifedFields(e) }} />
                                        </Box>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Box sx={{ '& > :not(style)': { mt: 3 }, }} noValidate autoComplete="off">
                                            <InputPhoneField label='Номер телефона' name='phone' size="small" value={phone} onChange={(e) => { setPhone(e.target.value); handleVerifedFields(e) }} />
                                        </Box>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <PasswordWithConfirm item xs={6} name='password' onChange={(e) => { setPassword(e.target.value); handleVerifedFields(e) }} />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <ReCAPTCHA
                                            sitekey={AppConfig.sitekey}
                                            render="explicit"
                                            onChange={verifyReCaptcha}
                                        />
                                    </Grid>
                                </Grid>
                            </Box>
                            <Box mt={2}>
                                <LoadingButton type='submit'
                                    color='primary'
                                    size="small"
                                    variant="contained"
                                    onClick={sendAuthRegistrationRequest}
                                    loading={isProgress}
                                    loadingIndicator="Отправка..."
                                    disabled={!isVerifed}
                                    fullWidth >
                                    Регистрация
                                </LoadingButton>
                            </Box>
                            <Box mt={2}>
                                <Grid container mt={2} spacing={0}>
                                    <Grid item xs={12} sm={6}>
                                        <Typography>
                                            <Link href="/AuthForm" >
                                                ввести пароль
                                            </Link>
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12} sm={6}>

                                    </Grid>
                                </Grid>
                            </Box>
                        </> :
                        <Box p={3}>
                            <Typography variant="body2">На ваш Email <b>{email}</b>  выслан код для подтверждения регистрации</Typography>
                        </Box>
                    }
                </Box>
            </Paper>
    )
}
