import React, { useState, useEffect, useContext } from "react"
import api from 'helpers/api';
import { AppConfig } from 'config.js';


import { isMobile } from 'react-device-detect';

import EmployeeBrowserView from 'pages/employee/BrowserView/EmployeeBrowserView'
import EmployeeMobileView from 'pages/employee/MobileView/EmployeeMobileView'
import { Context } from 'context';

export default function Employees() {

    const { handlerLoadProgress, errorLoadProgress } = useContext(Context)
    const [filter, setFilter] = useState([]);
    const [employees, setEmployees] = useState([]);

    const updateClients = (data) => {
        setEmployees(data);
    }

    const loadEmployees = (filter) => {
        handlerLoadProgress(true);

        return api.get(AppConfig.apiUrl + '/employee', { params: filter })
            .then(response => {
                updateClients(response.data);
                handlerLoadProgress(false);
            })
            .catch((error) => {
                handlerLoadProgress(false);
                errorLoadProgress('Ошибка загрузки данных');
            });
    }

    useEffect(() => {
        Promise.all([loadEmployees()]).then(response => { });
    }, [])


    return (
        <>
            {isMobile
                ?
                <EmployeeMobileView/>
                :
                <EmployeeBrowserView  employees={employees}/>
            }
        </>
    )
}
