import api from 'helpers/api';
import { AppConfig } from 'config.js';
import { useQuery } from "react-query";

export const useAllergens = () => {

    const { data: data, isLoading, isError } = useQuery(
        ['attr', 'allergens'],
        async () => {
            return await api.get(AppConfig.apiUrl + '/attr/index', { params: { attr_type_code: 'allergen' } });
        }
    );

    let result = [];
    data?.data?.items.map((item, index) => {
        result.push({ value: item.id, text: item.attr_name });
    });

    return { data: data?.data || [], selectItems: result, isLoading, isError }
}


export const useIntolerances = () => {

    const { data: data, isLoading, isError } = useQuery(
        ['attr', 'intolerances'],
        async () => {
            return await api.get(AppConfig.apiUrl + '/attr/index', { params: { attr_type_code: 'intolerance' } });
        }
    );

    let result = [];
    data?.data?.items.map((item, index) => {
        result.push({ value: item.id, text: item.attr_name });
    });

    return { data: data?.data || [], selectItems: result, isLoading, isError }
}