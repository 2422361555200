import React from "react";
import { Box, Paper, Grid, Typography, Link } from '@mui/material'

import AuthHeader from "pages/auth/AuthHeader";

import iconWrong from 'assets/icons/password-wrong.png';

export default function AuthWrongToken(props) {

    return (
        <Paper elevation={10} style={props.paperStyle}>
            <Box p={3}>
                <Grid align='center'>
                    <AuthHeader src={iconWrong} title='Ошибка' />
                </Grid>
                <Box mt={2} mb={2}>
                    <Typography align='center'>
                        Произошла ошибка проверки ключа, попробуйте <br></br>еще раз <Link href="/AuthRecoveryRequest">востановить пароль</Link>
                    </Typography>
                    
                </Box>
            </Box>
        </Paper>
    )
}