import React, { useState, useEffect } from 'react';
import api from 'helpers/api';
import { AppConfig } from 'config.js';


import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';



import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import FormSelect from './../../components/custom/inputs/FormSelect'
import InputPhoneField from './../../components/custom/inputs/InputPhoneField'
import InputEmailField from './../../components/custom/inputs/InputEmailField'
import InputSnilsField from './../../components/custom/inputs/InputSnilsField'
import ControllerTextField from './../../components/custom/inputs/ControllerTextField'

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';


import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import Skeleton from '@mui/material/Skeleton';

import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';


import { useForm, Controller } from "react-hook-form";
import File from 'components/custom/inputs/File';
import CientFormAnamnesis from 'pages/client/BrowserView/CientFormAnamnesis'
import CientFormVisitHistory from 'pages/client/BrowserView/CientFormVisitHistory'


const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
}));

const BootstrapDialogTitle = (props) => {
    const { children, onClose, ...other } = props;

    return (
        <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
            {children}
            {onClose ? (
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 16,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
            ) : null}
        </DialogTitle>
    );
};

const BootstrapDialogActions = (props) => {
    const { children, ...other } = props;

    return (
        <Box sx={{ m: 0, p: 1 }} >
            <DialogActions {...other}>
                {children}
            </DialogActions>
        </Box>
    );
};

BootstrapDialogTitle.propTypes = {
    children: PropTypes.node,
    onClose: PropTypes.func.isRequired,
};

BootstrapDialogActions.propTypes = {
    children: PropTypes.node
};


export default function ClientFormModal(props) {

    const defaults = {
        lastname: '',
        firstname: '',
        patronymic: '',
        sex: 'M',
        birthday: '',
        client_category_id: 1,
        snils: '',
        phone: '',
        email: '',
        avatar: {
            url: '',

        },
        passport: { series: '', number: '', issue_date: null, issued_by: '' },
        address: { address_type_1: { result: '' }, address_type_2: { result: '' } },
        insurance: {
            police_type_1: { insurance_subject_reg_number: 1 },
            police_type_2: { insurance_subject_reg_number: 1 }
        }
    };

    const [valueTab, setValueTab] = useState('1');
    const [client, setClient] = useState(defaults);
    const [anamnesis, setAnamnesis] = useState([]);
    const [medical_history, setMedicalHistory] = useState([]);

    const [isLoadingAnamnesis, setIsLoadingAnamnesis] = useState(false);
    const [isLoadingMedicalHistory, setIsLoadingMedicalHistory] = useState(false);


    const changeClient = (name, value) => {
        setClient({ ...client, ...{ [name]: value } });
    }

    const changeAnamnesis = (name, value) => {
        setAnamnesis({ ...anamnesis, ...{ [name]: value } });
    }


    const changeAvatarClient = (value) => {
        const avatar = { ...client.avatar, ...{ url: value } };
        changeClient('avatar', avatar);
    }

    const changePassportClient = (name, value) => {
        const passport = { ...client.passport, ...{ [name]: value } };
        changeClient('passport', passport);
    }

    const changeAdressClient = (address_type_id, value) => {
        const id = client.address['address_type_' + address_type_id] ? client.address['address_type_' + address_type_id].id : 0;
        const address = { ...client.address, ...{ ['address_type_' + address_type_id]: { id: id, address_type_id: address_type_id, result: value } } };
        changeClient('address', address);
    }


    const changeInsurance = (type_id, param, value) => {
        const object = { ...client.insurance['police_type_' + type_id], ...{ [param]: value, police_type_id: type_id } };
        const insurance = { ...client.insurance, ...{ ['police_type_' + type_id]: object } };
        changeClient('insurance', insurance);
    }

    const handleTabChange = (event, newValue) => {
        setValueTab(newValue);
    };

    const handleSave = () => {
        props.handleSave(client);
        saveAnamnesis();
    }

    const sex_list = [
        { value: 'M', text: 'Мужской' },
        { value: 'F', text: 'Женский' }
    ];

    const loadMedicalHistory = () => {
        setIsLoadingMedicalHistory(true);
        return api.get(AppConfig.apiUrl + '/client/' + client.id + '/medical_history')
            .then(response => {
                setMedicalHistory(response.data ? response.data : []);
                setIsLoadingMedicalHistory(false);

            })
            .catch((error) => {
                setIsLoadingMedicalHistory(false);
            });
    }

    const loadAnamnesis = () => {
        setIsLoadingAnamnesis(true);
        return api.get(AppConfig.apiUrl + '/client/' + client.id + '/anamnesis')
            .then(response => {
                setAnamnesis(response.data ? response.data : []);
                setIsLoadingAnamnesis(false);
            })
            .catch((error) => {
                setIsLoadingAnamnesis(false);
            });
    }

    const saveAnamnesis = () => {

        return api.post(AppConfig.apiUrl + '/client/' + client.id + '/anamnesis', anamnesis)
            .then(response => {
                console.log(response.data);
            })
            .catch((error) => {
            });
    }


    useEffect(() => {
        let client = { ...defaults, ...props.client }
        setClient(client)
        reset(client)
        if (client.lastname === null) {
            setError('lastname', { type: 'initial', message: 'initial' });
        }
        if (client.firstname === null) {
            setError('firstname', { type: 'initial', message: 'initial' });
        }
        if (client.phone === null) {
            setError('phone', { type: 'initial', message: 'initial' });
        }
    }, [props.client]);


    useEffect(() => {
        if (typeof client.id !== 'undefined') {
            Promise.all([
                loadMedicalHistory(),
                loadAnamnesis()
            ]).then(response => { });
        }
    }, [client.id]);

    //https://yandex.ru/search/?text=react-hook-form+default+values+empty&lr=967 19400
    //ClientFormModal
    const { register, control, reset, setError, clearErrors, resetField, trigger, formState: { errors, isDirty, isValid, dirtyFields } } = useForm({
        mode: 'onChange'
    });

    return (
        <>
            <form>
                <BootstrapDialog open={props.show} onClose={props.handleClose} maxWidth="lg" fullWidth={true} disableScrollLock={true}>
                    <BootstrapDialogTitle id="customized-dialog-title" onClose={props.handleClose}>
                        {!client.id ? 'Создание' : 'Редактирование'} клиента
                    </BootstrapDialogTitle>
                    <DialogContent dividers>
                        <TabContext value={valueTab}>
                            <Box>
                                <TabList onChange={handleTabChange}
                                    aria-label="lab API tabs example"
                                    variant="scrollable"
                                    scrollButtons
                                    allowScrollButtonsMobile
                                >
                                    <Tab label="Основная информация" value="1" />
                                    <Tab label="Мед. страхование" value="2" />
                                    <Tab label="Анамнез" value="3" />
                                    <Tab label="История посещений" value="4" />
                                </TabList>
                            </Box>
                            <TabPanel value="1">
                                <Box >
                                    <Grid container spacing={2} >
                                        <Grid item md={3} xs={12}>
                                            <File value={client.avatar.url || ''} onChange={(value) => changeAvatarClient(value)} object_id={client.id || 0} object_type="avatar" object="App\Models\Client\Client"></File>
                                        </Grid>
                                        <Grid item md={9} xs={12}>
                                            <Grid container spacing={2} >
                                                <Grid item md={4} xs={12}>
                                                    <ControllerTextField
                                                        control={control}
                                                        label="Фамилия"
                                                        rules={{ required: true, minLength: 2, pattern: /^[A-Za-zА-Яа-яё\s\-]+$/iu }}
                                                        name="lastname"
                                                        value={client.lastname || ''}
                                                        error={errors.lastname || false}
                                                        onChange={(e) => changeClient('lastname', e.target.value)}
                                                        size="small"
                                                        fullWidth />
                                                </Grid>
                                                <Grid item md={4} xs={6}>

                                                    <ControllerTextField
                                                        control={control}
                                                        label="Имя"
                                                        rules={{ required: true, minLength: 2, pattern: /^[A-Za-zА-Яа-яё\s\-]+$/iu }}
                                                        name="firstname"
                                                        value={client.firstname || ''}
                                                        error={errors?.firstname || false}
                                                        onChange={(e) => changeClient('firstname', e.target.value)}
                                                        size="small"
                                                        fullWidth />

                                                </Grid>
                                                <Grid item md={4} xs={6}>
                                                    <TextField label="Отчество" value={client.patronymic || ''} onChange={(e) => changeClient('patronymic', e.target.value)} size="small" fullWidth />
                                                </Grid>
                                                <Grid item md={4} xs={6}>
                                                    <FormSelect
                                                        label="Пол"
                                                        size="small"
                                                        items={sex_list}
                                                        value={client.sex || 0}
                                                        defaultValue={client.sex || 0}
                                                        onChange={(e) => changeClient('sex', e.target.value)} />
                                                </Grid>
                                                <Grid item md={4} xs={6}>
                                                    <Controller
                                                        control={control}
                                                        rules={{ required: false }}
                                                        name="birthday"
                                                        render={({ field: { onChange, name } }) => (
                                                            <LocalizationProvider dateAdapter={AdapterDateFns} >
                                                                <DesktopDatePicker
                                                                    label="Дата рождения"
                                                                    inputFormat="dd.MM.yyyy"
                                                                    name={name}
                                                                    value={client.birthday || null}
                                                                    onChange={(value) => {
                                                                        onChange(value)
                                                                        changeClient('birthday', value)
                                                                    }}
                                                                    renderInput={(params) => <TextField {...params} size="small" error={errors?.birthday} fullWidth />}
                                                                />
                                                            </LocalizationProvider>
                                                        )}
                                                    />
                                                </Grid>
                                                <Grid item md={4} xs={6}>
                                                    <InputSnilsField label="Снилс" name="snils" value={client.snils || ''} onChange={(e) => changeClient('snils', e.target.value)} fullWidth />
                                                </Grid>

                                                <Grid item md={4} xs={6}>
                                                    <Controller
                                                        control={control}
                                                        name="phone"
                                                        render={({ field: { onChange, name } }) => (
                                                            <InputPhoneField value={client.phone || ''}
                                                                onChange={(e) => {
                                                                    changeClient('phone', e.target.value)
                                                                    if (e.target.isValid) {
                                                                        clearErrors('phone');
                                                                        resetField('phone');
                                                                    } else {
                                                                        setError('phone', { type: 'custom', message: 'initial' });
                                                                    }
                                                                }
                                                                } />
                                                        )} />
                                                </Grid>
                                                <Grid item md={4} xs={6}>
                                                    <InputEmailField value={client.email || ''} onChange={(e) => changeClient('email', e.target.value)} />
                                                </Grid>
                                                <Grid item md={4} xs={6}>
                                                    <FormSelect
                                                        label="Категория"
                                                        size="small"
                                                        items={props.client_categories}
                                                        value={client?.client_category_id || 1}
                                                        defaultValue={client?.client_category_id || 1}
                                                        onChange={(e) => changeClient('client_category_id', e.target.value)} />
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Box>

                                <Box>
                                    <Typography mt={3} variant="overline" display="block" gutterBottom >Документ, удостоверяющий личность</Typography>
                                    <Grid container spacing={2} >
                                        <Grid item xs={6} md={3}>
                                            <FormControl fullWidth>
                                                <InputLabel id="document-type-label" size="small">Тип документа</InputLabel>
                                                <Select
                                                    labelId="document-type-label"
                                                    id="document-type-select"
                                                    label="Тип документа"
                                                    size="small"
                                                    value="1"
                                                >
                                                    <MenuItem value={1}>Паспорт</MenuItem>
                                                </Select>
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={6} md={3}>
                                            <ControllerTextField
                                                control={control}
                                                label="серия"
                                                rules={{ required: false, pattern: /^\d{4}$/ }}
                                                name="passport_series"
                                                value={client.passport ? client.passport.series : ''}
                                                error={errors.passport_series}
                                                onChange={(e) => changePassportClient('series', e.target.value)}
                                                size="small"
                                                fullWidth />

                                        </Grid>
                                        <Grid item xs={6} md={3}>
                                            <ControllerTextField
                                                control={control}
                                                label="номер"
                                                rules={{ required: false, pattern: /^\d{6}$/ }}
                                                name="passport_number"
                                                value={client.passport ? client.passport.number : ''}
                                                error={errors.passport_number}
                                                onChange={(e) => changePassportClient('number', e.target.value)}
                                                size="small"
                                                fullWidth />

                                        </Grid>
                                        <Grid item xs={6} md={3}>
                                            <LocalizationProvider dateAdapter={AdapterDateFns} >
                                                <DesktopDatePicker
                                                    label="Дата выдачи"
                                                    inputFormat="dd.MM.yyyy"
                                                    value={client.passport ? client.passport.issue_date : null}
                                                    onChange={(value) => changePassportClient('issue_date', value)}
                                                    renderInput={(params) => <TextField size="small" {...params} />}
                                                />
                                            </LocalizationProvider>
                                        </Grid>
                                    </Grid>
                                    <Grid container spacing={2} mt={0}>
                                        <Grid item xs={12}>
                                            <TextField label="кем выдан" value={client.passport ? client.passport.issued_by : ''} onChange={(e) => changePassportClient('issued_by', e.target.value)} size="small" fullWidth />
                                        </Grid>
                                    </Grid>

                                </Box>

                                <Box>
                                    <Typography mt={3} variant="overline" display="block" gutterBottom >Адреса регистрации и проживания</Typography>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12}>
                                            <TextField label="Адрес регистрации" value={client.address.address_type_1 ? client.address.address_type_1.result : ''} onChange={(e) => changeAdressClient('1', e.target.value)} size="small" fullWidth />
                                        </Grid>
                                    </Grid>
                                    <Grid container spacing={2} mt={0}>
                                        <Grid item xs={12}>
                                            <TextField label="Адрес проживания" value={client.address.address_type_2 ? client.address.address_type_2.result : ''} onChange={(e) => changeAdressClient('2', e.target.value)} size="small" fullWidth />
                                        </Grid>
                                    </Grid>
                                </Box>

                            </TabPanel>
                            <TabPanel value="2">
                                <Box>
                                    <Typography variant="overline" display="block" gutterBottom >Медецинская карта</Typography>
                                    <Grid container spacing={2}>
                                        <Grid item md={3} xs={6} >
                                            <TextField label="Номер карты" value="" size="small" fullWidth />
                                        </Grid>
                                        <Grid item md={3} xs={6}>
                                            <TextField label="Дата выдачи" value="" size="small" fullWidth />
                                        </Grid>
                                    </Grid>
                                </Box>

                                <Box>
                                    <Typography mt={3} variant="overline" display="block" gutterBottom >Полис ОМС</Typography>
                                    <Grid container spacing={2}>
                                        <Grid item md={3} xs={6}>
                                            <FormSelect
                                                label="Компания"
                                                size="small"
                                                items={props.insurance_subjects}
                                                value={client.insurance.police_type_1 ? client.insurance.police_type_1.insurance_subject_reg_number : 0}
                                                defaultValue={client.insurance.police_type_1 ? client.insurance.police_type_1.insurance_subject_reg_number : 0}
                                                onChange={(e) => changeInsurance(1, 'insurance_subject_reg_number', e.target.value)}
                                            />
                                        </Grid>
                                        <Grid item md={2} xs={6}>
                                            <TextField label="Серия" value={client.insurance.police_type_1 ? client.insurance.police_type_1.series : 0}
                                                onChange={(e) => changeInsurance(1, 'series', e.target.value)} size="small" fullWidth />
                                        </Grid>
                                        <Grid item md xs={6}>
                                            <TextField label="Номер" value={client.insurance.police_type_1 ? client.insurance.police_type_1.number : 0}
                                                onChange={(e) => changeInsurance(1, 'number', e.target.value)} size="small" fullWidth />
                                        </Grid>
                                        <Grid item md={2} xs={6}>
                                            <LocalizationProvider dateAdapter={AdapterDateFns} >
                                                <DesktopDatePicker
                                                    label="период с"
                                                    inputFormat="dd.MM.yyyy"
                                                    value={client.insurance.police_type_1 ? client.insurance.police_type_1.date_from : 0}
                                                    onChange={(value) => changeInsurance(1, 'date_from', value)}
                                                    renderInput={(params) => <TextField size="small" {...params} />}
                                                />
                                            </LocalizationProvider>
                                        </Grid>
                                        <Grid item md={2} xs={6}>
                                            <LocalizationProvider dateAdapter={AdapterDateFns} >
                                                <DesktopDatePicker
                                                    label="период по"
                                                    inputFormat="dd.MM.yyyy"
                                                    value={client.insurance.police_type_1 ? client.insurance.police_type_1.date_to : 0}
                                                    onChange={(value) => changeInsurance(1, 'date_to', value)}
                                                    renderInput={(params) => <TextField size="small" {...params} />}
                                                />
                                            </LocalizationProvider>                                        </Grid>
                                        <Grid item xs={12}>
                                            <TextField label="Наименование организации" value={client.insurance.police_type_1 ? client.insurance.police_type_1.issued_by : 0}
                                                onChange={(e) => changeInsurance(1, 'issued_by', e.target.value)}
                                                size="small" fullWidth />
                                        </Grid>
                                    </Grid>
                                </Box>

                                <Box>
                                    <Typography mt={3} variant="overline" display="block" gutterBottom >Полис ДМС</Typography>
                                    <Grid container spacing={2}>
                                        <Grid item md={3} xs={6}>
                                            <FormSelect
                                                label="Компания1"
                                                size="small"
                                                items={props.insurance_subjects}
                                                value={client.insurance.police_type_2 ? client.insurance.police_type_2.insurance_subject_reg_number : 0}
                                                defaultValue={client.insurance.police_type_2 ? client.insurance.police_type_2.insurance_subject_reg_number : 0}
                                                onChange={(e) => changeInsurance(2, 'insurance_subject_reg_number', e.target.value)}
                                            />
                                        </Grid>
                                        <Grid item md={2} xs={6}>
                                            <TextField label="Серия" value={client.insurance.police_type_2 ? client.insurance.police_type_2.series : 0} onChange={(e) => changeInsurance(2, 'series', e.target.value)} size="small" fullWidth />
                                        </Grid>
                                        <Grid item md xs={6}>
                                            <TextField label="Номер" value={client.insurance.police_type_2 ? client.insurance.police_type_2.number : 0} onChange={(e) => changeInsurance(2, 'number', e.target.value)} size="small" fullWidth />
                                        </Grid>
                                        <Grid item md={2} xs={6}>
                                            <LocalizationProvider dateAdapter={AdapterDateFns} >
                                                <DesktopDatePicker
                                                    label="период с"
                                                    inputFormat="dd.MM.yyyy"
                                                    value={client.insurance.police_type_2 ? client.insurance.police_type_2.date_from : 0}
                                                    onChange={(value) => changeInsurance(2, 'date_from', value)}
                                                    renderInput={(params) => <TextField size="small" {...params} />}
                                                />
                                            </LocalizationProvider>
                                        </Grid>
                                        <Grid item md={2} xs={6}>
                                            <LocalizationProvider dateAdapter={AdapterDateFns} >
                                                <DesktopDatePicker
                                                    label="период по"
                                                    inputFormat="dd.MM.yyyy"
                                                    value={client.insurance.police_type_2 ? client.insurance.police_type_2.date_to : 0}
                                                    onChange={(value) => changeInsurance(2, 'date_to', value)}
                                                    renderInput={(params) => <TextField size="small" {...params} />}
                                                />
                                            </LocalizationProvider>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <TextField label="Наименование организации" value={client.insurance.police_type_2 ? client.insurance.police_type_2.issued_by : 0}
                                                onChange={(e) => changeInsurance(2, 'issued_by', e.target.value)}
                                                size="small" fullWidth />
                                        </Grid>
                                    </Grid>
                                </Box>

                            </TabPanel>
                            <TabPanel value='3'>
                                {!isLoadingAnamnesis
                                    ?
                                    <CientFormAnamnesis anamnesis={anamnesis} changeAnamnesis={changeAnamnesis} allergy_list={props.allergy_list} intolerance_list={props.intolerance_list} />
                                    :
                                    <Stack spacing={1}>
                                        <Skeleton variant="rounded" height={60} />
                                        <Skeleton variant="rounded" height={60} />
                                        <Skeleton variant="rounded" height={240} />
                                    </Stack>
                                }
                            </TabPanel>
                            <TabPanel value="4">
                                {!isLoadingMedicalHistory
                                    ?
                                    <CientFormVisitHistory items={medical_history} />
                                    :
                                    <Stack spacing={1}>
                                        <Skeleton variant="rounded" height={60} />
                                        <Skeleton variant="rounded" height={60} />
                                        <Skeleton variant="rounded" height={60} />
                                        <Skeleton variant="rounded" height={60} />
                                        <Skeleton variant="rounded" height={60} />
                                    </Stack>
                                }
                            </TabPanel>
                        </TabContext>
                    </DialogContent>
                    <BootstrapDialogActions>
                        <Button variant="secondary" onClick={props.handleClose} >
                            Закрыть
                        </Button>
                        <Button variant="success" onClick={handleSave} disabled={!isValid}>
                            Сохранить
                        </Button>
                    </BootstrapDialogActions>
                </BootstrapDialog>
            </form>
        </>)
};