import React from "react";
import { getAge } from 'helpers/functions';
import moment from 'moment';

import {
    DataGrid,
    GridToolbarContainer,
    GridToolbarQuickFilter,
    GridToolbarExport,
    ruRU
} from '@mui/x-data-grid';

import { useTheme } from '@mui/material/styles';
import { Typography, Box, Grid } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import Avatar from '@mui/material/Avatar';
import DeleteIcon from '@mui/icons-material/Delete';
import ModeEditIcon from '@mui/icons-material/ModeEdit';

export default function EmployeeBrowserView(props) {

    const theme = useTheme();

    const columns = [
        {
            field: 'fullName',
            headerName: 'ФИО',
            description: 'This column has a value getter and is not sortable.',
            sortable: true,
            type: 'string',
            width: 360,
            valueGetter: (params) =>
                `${params.row.lastname || ''} ${params.row.firstname || ''} ${params.row.patronymic || ''} ${params.row.birthday || ''}`,
            renderCell: (params) => {
                return <Stack direction="row" spacing={2}>
                    <Avatar alt={params.row.lastname || ''} src={params.row.avatar ? params.row.avatar.url : ''} onClick={() => { props.editButtonHandler(params.row.id) }} />
                    <div>
                        <Typography >{params.row.lastname || ''} {params.row.firstname || ''} {params.row.patronymic || ''}</Typography>
                        <Typography variant="body2">{moment(params.row.birthday).format('DD.MM.YYYY') || ''}, {getAge(params.row.birthday) || ''} лет</Typography>
                    </div>
                </Stack>
            }
        },
        {
            field: 'phone',
            headerName: 'Телефон',
            type: 'string',
            width: 200,
            editable: false,
        },
        {
            field: 'client_category_id',
            headerName: 'Категория',
            type: 'string',
            width: 200,
            editable: false,
            valueGetter: (params) =>
                `${params.row.client_category ? params.row.client_category.category_name : ''}`,
            renderCell: (params) => {
                return <div >
                    <Typography>{params.row.client_category ? params.row.client_category.category_name : ''}</Typography>
                </div>
            }
        },
        {
            field: 'actions',
            headerName: '',
            type: 'string',
            width: 150,
            sortable: false,
            disableClickEventBubbling: true,
            renderCell: (params) => {
                return <Stack direction="row">
                    <IconButton onClick={() => { props.editButtonHandler(params.row.id) }} color="primary"><ModeEditIcon /></IconButton>
                    <IconButton onClick={() => { props.deleteButtonHandler(params.row.id) }} color="error"> <DeleteIcon /></IconButton>
                </Stack>;
            }
        }
    ];


    const CustomGridToolbar = (props) => {
        return (
            <Box p={2}>
                <GridToolbarContainer>
                    <Grid container spacing={1}>
                        <Grid item lg={2} md={4} xs={12}>
                            <GridToolbarQuickFilter size="small" sx={{ width: '100%' }} />
                        </Grid>
                        <Grid item lg={9} md={4} xs={0}>

                        </Grid>
                        <Grid item lg={1} md={4} xs={12}>
                            <GridToolbarExport csvOptions={{
                                fileName: 'customerDataBase',
                                delimiter: ';',
                                utf8WithBom: true,
                            }} />
                        </Grid>
                    </Grid>
                </GridToolbarContainer>
            </Box >
        );
    }


    return (

        <Box mt={2} >
            <DataGrid
                rows={props.employees}
                columns={columns}
                pageSize={15}
                rowsPerPageOptions={[15]}
                checkboxSelection
                disableSelectionOnClick
                disableColumnFilter
                disableColumnSelector
                disableDensitySelector
                localeText={ruRU.components.MuiDataGrid.defaultProps.localeText}
                autoHeight={true}
                components={{
                    Toolbar: CustomGridToolbar
                }}
                sx={{
                    backgroundColor: theme.palette.background.default
                }}
            />
        </Box>
    )
}