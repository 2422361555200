import React, { useEffect, useContext } from 'react';
import moment from 'moment';

import {
    DataGrid,
    GridToolbarContainer,
    GridToolbarQuickFilter,
    GridToolbarExport,
    ruRU
} from '@mui/x-data-grid';

import { BrowserView, MobileView } from 'react-device-detect';
import { useTheme } from '@mui/material/styles';
import Stack from '@mui/material/Stack';
import Avatar from '@mui/material/Avatar';
import { Typography, Box, Grid } from '@mui/material';
import { Context } from '../../context';
import { useUsers } from 'hooks/useUsers';

function getAge(dateString) {
    var today = new Date();
    var birthDate = new Date(dateString);
    var age = today.getFullYear() - birthDate.getFullYear();
    var m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
        age--;
    }
    return age;
}



const columns = [
    {
        field: 'fullName',
        headerName: 'ФИО',
        description: 'This column has a value getter and is not sortable.',
        sortable: true,
        type: 'string',
        width: 360,
        valueGetter: (params) =>
            `${params.row.lastname || ''} ${params.row.firstname || ''} ${params.row.patronymic || ''} ${params.row.birthday || ''}`,
        renderCell: (params) => {
            return <Stack direction="row" spacing={2}>
                <Avatar alt={params.row.lastname || ''} src={params.row.avatar ? params.row.avatar.url : ''} />
                <div>
                    <Typography >{params.row.lastname || ''} {params.row.firstname || ''} {params.row.patronymic || ''}</Typography>
                    <Typography variant="body2">{moment(params.row.birthday).format('DD.MM.YYYY') || ''}, {getAge(params.row.birthday) || ''} лет</Typography>
                </div>
            </Stack>
        }
    },
    {
        field: 'login',
        headerName: 'Логин',
        type: 'string',
        width: 240,
        editable: false,
        renderCell: (params) => {
            return <>
                <Typography>{params.row.login ? params.row.login : ''}</Typography>
            </>
        }
    },
    {
        field: 'status_id',
        headerName: 'Статус',
        type: 'string',
        width: 240,
        editable: false,
        valueGetter: (params) =>
            `${params.row.status ? params.row.status.name : ''}`,
        renderCell: (params) => {
            return <div >
                <Typography>{params.row.status ? params.row.status.name : ''}</Typography>
            </div>
        }
    },
    {
        field: 'contacts',
        headerName: 'Контакты',
        type: 'string',
        width: 240,
        editable: false,
        valueGetter: (params) =>
            `${params.row.email ? params.row.email : ''} ${params.row.phone ? params.row.phone : ''}`,
        renderCell: (params) => {
            return <>
                <Typography variant="body2">{params.row.email ? params.row.email : ''}</Typography>
                <Typography variant="body2">{params.row.phone ? params.row.phone : ''}</Typography>
            </>
        }
    },
    {
        field: 'Авторизация',
        headerName: 'Авторизация',
        type: 'string',
        flex: 1,
        editable: false,
        renderCell: (params) => {
            return <div >
                {params.row.aouth_tokens ?
                    params.row.aouth_tokens.map((item, index) => (
                        <div key={'aouth_token_' + index}>
                            <Typography>{item.device_type} {item.platform_family} {item.platform_version}</Typography>
                            <Typography variant="body2">авторизирован:{moment(item.created_at).format('DD.MM.YYYY H:mm:ss')}</Typography>
                        </div>
                    ))
                    : ''}

            </div>
        }
    },
];



export default function Users() {

    const theme = useTheme();
    const { handlerLoadProgress, errorLoadProgress } = useContext(Context)
    const { users, isLoading, isError } = useUsers()

    useEffect(() => {
        handlerLoadProgress(isLoading);
    }, [isLoading, handlerLoadProgress])

    useEffect(() => {
        if (isError) {
            errorLoadProgress('Ошибка загрузки данных пользователей')
        }
    }, [isError, errorLoadProgress])


    const CustomGridToolbar = () => {
        return (
            <Box p={2}>
                <GridToolbarContainer>
                    <Grid container spacing={1}>
                        <Grid item lg={2} md={4} xs={12}>
                            <GridToolbarQuickFilter size="small" sx={{ width: '100%' }} />
                        </Grid>
                        <Grid item lg={9} md={4} xs={0}>

                        </Grid>
                        <Grid item lg={1} md={4} xs={12}>
                            <GridToolbarExport csvOptions={{
                                fileName: 'customerDataBase',
                                delimiter: ';',
                                utf8WithBom: true,
                            }} />
                        </Grid>
                    </Grid>
                </GridToolbarContainer>
            </Box >
        );
    }

    return (
        <>
            <BrowserView>
                <h1>This is rendered only in browser</h1>
            </BrowserView>
            <MobileView>
                <h1>This is rendered only on mobile</h1>
            </MobileView>
            <Box mt={2}>

                <DataGrid
                    rows={users}
                    columns={columns}
                    pageSize={15}
                    rowsPerPageOptions={[15]}
                    checkboxSelection
                    disableSelectionOnClick
                    disableColumnFilter
                    disableColumnSelector
                    disableDensitySelector
                    localeText={ruRU.components.MuiDataGrid.defaultProps.localeText}
                    autoHeight={true}
                    components={{
                        Toolbar: CustomGridToolbar
                    }}
                    sx={{
                        backgroundColor: theme.palette.background.default
                    }}
                />
            </Box>
        </>
    )
}
