import React from "react";
import { getAge } from 'helpers/functions';

import { Box, Grid, Typography } from "@mui/material";
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';

import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack';
import Avatar from '@mui/material/Avatar';

import Chip from '@mui/material/Chip';
import Rating from '@mui/material/Rating';

import ModeEditIcon from '@mui/icons-material/ModeEdit';

import ClientVisit from './ClientVisits'


export default function ClientItem(props) {



    return (
        <Box pt={1} pb={1}>
            <Card>
                <CardContent sx={{ p: 1 }}>
                    <Grid container p={0}>
                        <Grid item xs={12}>
                            <Stack direction="row" spacing={2}>
                                <Rating name="disabled" value={4}  disabled />
                                {props.client_category ?
                                    <Chip label={ props.client_category.category_name||''} sx={{ maxWidth: 120 }} color={props.client_category.mui_class||'default'} size="small" />
                                    : <></>
                                }

                            </Stack>
                        </Grid>
                    </Grid>
                    <Grid container mt={1}>
                        <Grid item xs={3}>
                            <Avatar src={props.avatar?props.avatar.url:''} sx={{ width: 48, height: 48 }}></Avatar>
                        </Grid>
                        <Grid item xs={9}>
                            <Typography variant="body1" sx={{ 'whiteSpace': 'nowrap', 'maxHeight': 180, 'overflowX': 'hidden' }}>{props.lastname} {props.firstname}</Typography>
                            <Typography variant="caption" display="block" mt={1}>
                                {props.phone}&nbsp;&nbsp;{getAge(props.birthday)} лет
                            </Typography>
                        </Grid>
                    </Grid>

                    <Grid container mt={1}>
                        <Grid item xs={12}>
                            <ClientVisit />
                        </Grid>
                    </Grid>
                </CardContent>
                <Divider variant="middle" light />
                <CardActions sx={{ justifyContent: 'end' }}>
                    <Stack direction="row" spacing={1}>
                        <Chip
                            label="редактировать"
                            onClick={() => { props.editButtonHandler(props.id) }}
                            onDelete={() => { props.editButtonHandler(props.id) }}
                            size="small"
                            color="primary"
                            variant="outlined"
                            deleteIcon={<ModeEditIcon />} />
                        <Chip label="удалить"
                            onClick={() => { props.deleteButtonHandler(props.id) }}
                            onDelete={() => { props.deleteButtonHandler(props.id) }}
                            size="small"
                            color="error"
                            variant="outlined"
                        />
                    </Stack>
                </CardActions>
            </Card>
        </Box>
    )
}