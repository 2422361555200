import React from 'react';

import { Link } from "react-router-dom";
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';


import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { fas } from '@fortawesome/free-solid-svg-icons'
import { far } from '@fortawesome/free-regular-svg-icons'
import { fab } from '@fortawesome/free-brands-svg-icons'

library.add(fas, far, fab);

export default function MenuItem(props) {
    const open = props.open;
    return (
        <ListItem key={props.name} onClick={(e) => props.setTitle(props)} disablePadding sx={{ display: 'block' }}>
            <Link to={props.link} style={{ textDecoration: 'none', color: 'inherit' }}>
                <ListItemButton sx={{ minHeight: 48, justifyContent: open ? 'initial' : 'center', px: 2.5 }}>
                    <ListItemIcon sx={{ minWidth: 0, mr: open ? 2 : 'auto', justifyContent: 'center' }} >
                        <FontAwesomeIcon icon={props.icon} style={{ width: '18px' }} />
                    </ListItemIcon>
                    <ListItemText primary={props.name} sx={{ opacity: open ? 1 : 0 }} />
                </ListItemButton>
            </Link>
        </ListItem>
    )
}