import React, { useState, useEffect } from 'react';
import { AppConfig } from 'config.js';
import { useSearchParams } from "react-router-dom";
import { Grid, Box, Paper, TextField, Button, Typography, Link } from '@mui/material'

import Progress from 'components/Progress'
import AuthHeader from 'pages/auth/AuthHeader.js'

import icon from 'assets/icons/free-icon-reset-password-6195699.png';
import AuthWrongToken from 'pages/auth/AuthWrongToken.js';
import AuthPasswordChanged from 'pages/auth/AuthPasswordChanged.js';


export default function AuthRecovery(props) {

    const btnstyle = { margin: '8px 0' }

    const [isPassChanged, setIsPassChanged] = useState(false);
    const [isValidToken, setIsvalidToken] = useState(false);
    const [password, setPassword] = useState('');
    const [c_password, setCPassword] = useState('');
    const [isPassSame, setIsPassSame] = useState(false);
    const [isWeakPass, setIsWeakPass] = useState(false);
    const [isProgress, setisProgress] = useState(false);

    const [searchParams, setSearchParams] = useSearchParams();


    useEffect(() => { if (!isValidToken) passwordRecoveryValidToken() }, []);

    useEffect(() => {
        setIsPassSame(password === c_password ? true : false);
    }, [password, c_password])


    const passwordRecoveryValidToken = () => {

        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        var oauth_url = AppConfig.apiUrl + '/user/pass_recovery/valid_token';

        var raw = JSON.stringify(
            {
                "remember_token": searchParams.get('remember_token'),
                "client_id": AppConfig.client_id,
                "client_secret": AppConfig.client_secret
            }
        );

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw
        };

        setisProgress(true);
        fetch(oauth_url, requestOptions)
            .then(response => response.json())
            .then(data => {
                setisProgress(false);
                if (data.status === true) {
                    setIsvalidToken(true);
                }
            })
            .catch(error => console.log('error', error));
    }


    const sendAuthRecovery = () => {

        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        var oauth_url = AppConfig.apiUrl + '/user/pass_recovery/recovery';
        var raw = JSON.stringify(
            {
                "password": password,
                "c_password": c_password,
                "remember_token": searchParams.get('remember_token'),
                "client_id": AppConfig.client_id,
                "client_secret": AppConfig.client_secret
            }
        );

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw
        };

        fetch(oauth_url, requestOptions)
            .then(response => {
                if (response.status == 200) {
                    setIsPassChanged(true);
                }
            }
            )
            .catch(error => console.log('error', error));
    }



    return (
        <>
            {isProgress
                ?
                <Progress />
                : <>
                    {isValidToken ?
                        <>
                            {isPassChanged
                                ?
                                <AuthPasswordChanged {...props} />
                                :
                                <Paper elevation={10} style={props.paperStyle} >
                                    <Box p={3}>
                                        <Grid align='center'>
                                            <AuthHeader src={icon} title='Востановление пароля' />
                                        </Grid>
                                        <Box>
                                            <Box component="form" sx={{ "& .MuiTextField-root": { mt: 3 } }} noValidate>
                                                <div>
                                                    <TextField label='Пароль' onChange={(e) => { setPassword(e.target.value); }} placeholder='Введите пароль'
                                                        helperText={!isPassSame ? 'пароли не совпадают' : ''} error={!isPassSame} fullWidth />
                                                </div>
                                                <div>
                                                    <TextField label='Повторите пароль' onChange={(e) => { setCPassword(e.target.value); }} placeholder='Повторите пароль' type='password'
                                                        helperText={!isPassSame ? 'пароли не совпадают' : ''} error={!isPassSame} fullWidth />
                                                </div>
                                            </Box>
                                            <Box mt={2}>
                                                <Button type='submit' color='primary' variant="contained"
                                                    onClick={sendAuthRecovery} style={btnstyle}
                                                    disabled={!isPassSame || isWeakPass} fullWidth
                                                >Востановить</Button>
                                            </Box>
                                            <Box mt={2}>
                                                <Typography align='right'>
                                                    <Link href="/AuthForm" >
                                                        я вспомнил пароль
                                                    </Link>
                                                </Typography>
                                            </Box>
                                        </Box>
                                    </Box>
                                </Paper>
                            }
                        </>
                        :
                        <AuthWrongToken  {...props} />
                    }
                </>}
        </>
    )
}
