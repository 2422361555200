import React, { useState, useEffect } from "react"
import { Paper, Box, Grid, Typography } from "@mui/material"
import { AppConfig } from 'config.js';
import { setAccessToken } from 'helpers/api';

import { useSearchParams } from "react-router-dom";

import Progress from 'components/Progress'

export default function AuthRegisterConfirmation(props) {


    const [isValidToken, setIsvalidToken] = useState(false);
    const [isProgress, setisProgress] = useState(false);
    const [searchParams, setSearchParams] = useSearchParams();

    const validToken = () => {

        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        var oauth_url = AppConfig.apiUrl + '/user/registration/valid_token';

        var raw = JSON.stringify(
            {
                "remember_token": searchParams.get('remember_token'),
                "email_verified_at": true,
                "client_id": AppConfig.client_id,
                "client_secret": AppConfig.client_secret
            }
        );

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw
        };

        setisProgress(true);
        fetch(oauth_url, requestOptions)
            .then(response => response.json())
            .then(data => {
                setisProgress(false);
                if (data.status === true) {
                    setIsvalidToken(true);
                    setAccessToken(data.data.access_token);
                    props.handleisAuth(true);
                    window.location.href = "/profile";
                }

            })
            .catch(error => console.log('error', error));
    }

    useEffect(() => { if (!isValidToken) validToken() }, []);


    return (
        <>{isProgress
            ?
            <Grid>
                <Progress />
                <Paper elevation={10} style={props.paperStyle}>
                    <Typography sx={{ textAlign: 'center' }}>Верификация</Typography>
                </Paper>
            </Grid>

            :
            <Grid>
                <Paper elevation={10} style={props.paperStyle}>
                    <Box p={3}>
                        {isValidToken
                            ?
                            <Typography sx={{ textAlign: 'center' }}>Код проверен, авторизация..</Typography>
                            :
                            <Typography sx={{ textAlign: 'center' }}>Ошибка проверки кода верификации, запросите востановление пароля</Typography>
                        }
                    </Box>
                </Paper>
            </Grid>

        }

        </>
    )


}