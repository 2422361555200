import React from "react";

import { Box, Grid,Typography,Chip,Card } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import ArticleIcon from '@mui/icons-material/Article';

export default function CientFormVisitHistory(props) {
    return (
        <Box sx={{ overflowY: 'auto', maxHeight: 480 }}>
            {props.items.map((item, index) => (
                <Box mt={1} p={1} key={index}>
                    <Card mt={1}>
                        <Grid container spacing={2} >
                            <Grid item xs={10} >
                                <Box pl={2} pb={1} >
                                    <Typography variant="overline" mt={2}>Лечение, дата приема 13.04.2022 13:30</Typography>
                                    <Typography variant="body2" display="block" >
                                        Лечащий врач: Коновалов И.П
                                    </Typography>

                                    <Box>
                                        <Chip label="чистка и профилактика" size="small" />
                                        <Chip label="удаление всех зубов" variant="outlined" size="small" />
                                        <Chip label="протезирование" variant="outlined" size="small" />
                                        <Chip label="консультация" variant="outlined" size="small" />
                                    </Box>
                                </Box>
                            </Grid>
                            <Grid item xs={2} sx={{ my: 2, mx: 'auto', 'textAlign': 'center' }}>
                                <IconButton aria-label="add to favorites">
                                    <ArticleIcon fontSize="large" />
                                </IconButton>
                            </Grid>
                        </Grid>
                    </Card>
                </Box>
            ))}
        </Box>

    )
}