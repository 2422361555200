import React from 'react';
import { Controller } from "react-hook-form";
import TextField from '@mui/material/TextField';

export default function ControllerTextField(props) {

    const handlerChange = (e) => {
        props.onChange(e)
    }

    return (
        <Controller
            {...props}
            render={({ field: { onChange, name } }) => (
                <TextField
                    onChange={(e) => {
                        onChange(e)
                        handlerChange(e)
                    }}
                    name={name}
                    value={props.value}
                    size={props.size}
                    label={props.label}
                    error={props.error}
                    fullWidth />
            )}
        />
    )
}