import * as React from "react";
import api from 'helpers/api';
import { AppConfig } from 'config.js';

import Box from "@mui/material/Box";
import { yellow } from '@mui/material/colors';

import ImageList from "@mui/material/ImageList";
import ImageListItem from "@mui/material/ImageListItem";
import ImageListItemBar from '@mui/material/ImageListItemBar';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import CircularProgress from '@mui/material/CircularProgress';
import AutorenewIcon from '@mui/icons-material/Autorenew';
import StarIcon from '@mui/icons-material/Star';
import StarBorderIcon from '@mui/icons-material/StarBorder';
import IconButton from '@mui/material/IconButton';
import AddAPhotoIcon from '@mui/icons-material/AddAPhoto';


export default function FilialImages(props) {
    const cols = 6
    const width = 184
    const height = 184
    const conteinerWidth = cols * (width + 2)
    const BoxStyle = { width: width, height: height, borderWidth: '3px', borderColor: '#cccccc', borderStyle: 'solid', borderRadius: '4px', 'cursor': 'pointer' }

    const [files, setFiles] = React.useState([]);
    const [isUploading, setisUploading] = React.useState(false);
    const [isDefaultChanging, setIsDefaultChanging] = React.useState(false);
    const [isDeleting, setIsDeleting] = React.useState(false);
    const inputFile = React.useRef(null)


    const selectFile = (files) => {
        let params = {
            file: files[0],
            'object_id': props.object_id || 0,
            'object': props.object || null,
            'object_type': props.object_type|| 'image',
            'single': false
        }

        setisUploading(true);
        api.post(AppConfig.apiUrl + '/file/upload', params, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        }).then(response => {
            addFile(response.data?.result?.id, response.data?.result?.url)
            setisUploading(false);
        }).catch((error) => {
            setisUploading(false);
        });
    }

    const addFile = (id, url) => {
        setFiles((files) => [...files, { id: id, url: url }])
    }

    const changeDefault = (id) => {
        setIsDefaultChanging(true);
        api.post(AppConfig.apiUrl + '/file/default', { id: id }).then(response => {
            let id = response.data?.id || 0
            for (var i = 0; i < files.length; i++) {
                let file = files[i];
                files[i].object_type = (file.id === id) ? 'default_image' : 'image';
            }
            setFiles((files) => [...files])
            setIsDefaultChanging(false);
            props.onChange()
        });
    }


    const handleDelete = (id, url) => {

        let params = {
            'url': url
        }
        api.post(AppConfig.apiUrl + '/file/delete', params, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        }).then(response => {
            let new_files = files.filter(function (obj) {
                return obj.id !== id;
            });
            setFiles(new_files)
            setisUploading(false);
        }).catch((error) => {
            setisUploading(false);
        });

    }


    React.useEffect(() => {
        setFiles(props.files)
    }, [props.files]);


    return (
        <ImageList sx={{ width: conteinerWidth }} cols={cols} rowHeight={height}>
            {files.map((item) => (
                <ImageListItem key={'DayItem_' + item.id}>
                    <img
                        src={`${item.url}`}
                        srcSet={`${item.url}`}
                        alt={item.title}
                        loading="lazy"
                    />
                    <ImageListItemBar
                        sx={{
                            background:
                                'linear-gradient(to bottom, rgba(0,0,0,0.7) 0%, ' +
                                'rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)',
                        }}
                        position="top"
                        actionIcon={
                            <>
                                <IconButton
                                    onClick={() => changeDefault(item.id)}
                                    sx={{ color: 'white', float: 'left' }}
                                    aria-label={`star ${item.title}`}
                                >
                                    {isDefaultChanging ?
                                        <CircularProgress color='warning' size={24} />
                                        :
                                        item.object_type == 'default_image' ?
                                            <StarIcon sx={{ color: yellow[500] }} />
                                            :
                                            <StarBorderIcon />
                                    }
                                </IconButton>

                                <IconButton
                                    onClick={() => handleDelete(item.id, item.url)}
                                    sx={{ color: 'white' }} >
                                    <DeleteOutlinedIcon />
                                </IconButton>
                            </>

                        }
                        actionPosition="right"
                    />

                </ImageListItem>
            ))}

            <Box sx={BoxStyle} onClick={() => inputFile.current.click()}>
                <Box mt={8} ml={8}>
                    <IconButton color="success" fontSize="large" aria-label="upload picture" >
                        <input hidden accept="image/*" type="file" ref={inputFile} onChange={(e) => selectFile(e.target.files)} />
                        <AddAPhotoIcon />
                    </IconButton >
                </Box>
            </Box>
        </ImageList >
    );
}