import React from 'react';
import { Typography, Grid, Box, Stack, Avatar, Menu, MenuItem, Divider, IconButton } from '@mui/material';
import { MoreVert } from '@mui/icons-material';


export default function RenderResourcelabelContent(props) {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const areaWidth = 280;
    return (<Stack
        direction="row"
        spacing={2}
        ml={1}
    >
        <Avatar src={props.src} alt={props.title || ''}></Avatar>
        <Box sx={{ width: 143 }}>
            <Typography>{props.title}</Typography>
            <Typography variant="body2">{props.employee_type_name}</Typography>
        </Box>
        <Box sx={{ width: 40, textAlign: 'right' }} >
            <IconButton
                onClick={handleClick}
            >
                <MoreVert />
            </IconButton>
            <Menu
                anchorEl={anchorEl}
                id="schedule-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
            >
                <MenuItem>
                    создать расписание
                </MenuItem>
                <MenuItem >
                    скопировать расписание
                </MenuItem>
                <Divider />
                <MenuItem >
                    выгрузка статистики
                </MenuItem>
            </Menu>

        </Box>
    </Stack >)
}