import React from "react";

import { Grid } from '@mui/material'

export default function AuthWrapper(props) {

    const defaults = { sizes: { xs: 12, sm: 6, md: 3 } }
    props = {...defaults,...props}

    return (
        <Grid container justifyContent="center" >
            <Grid item {...props.sizes} p={1}>
                {props.inner}
            </Grid>
        </Grid>
    )
}