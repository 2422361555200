import React from 'react';

import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';


const ITEM_HEIGHT = 60;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};


export default function FormSelect(props) {
    const labelId = `${props.name}-label`;
    
    return (
        <FormControl fullWidth>
            <InputLabel id={labelId} size="small">{props.label}</InputLabel>
            <Select
                labelId={labelId}
                label={props.label}
                value={props.value}
                defaultValue={props.defaultValue}
                onChange={props.onChange}
                size={props.size}
                MenuProps={MenuProps}
            >
                {props.items.map((item) => (
                    <MenuItem value={item.value} key={Math.random()} >{item.text}</MenuItem>
                ))}
            </Select>
        </FormControl >
    )
}