import React, {useEffect, useContext } from 'react';


import { Context } from 'context';

export default function Specialization() {
    
    const { setTitle } = useContext(Context)

    useEffect(() => {
        setTitle('Специализации');
    }, [setTitle])


    return (
        <>Specialization</>
    )
}