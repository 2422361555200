import React, { useState, memo, useEffect } from 'react';
import api from 'helpers/api';
import { AppConfig } from 'config.js';
import { Context } from 'context'
import logo from 'assets/logo.png';

import { QueryClient, QueryClientProvider } from 'react-query'
import { ReactQueryDevtools } from 'react-query/devtools';


import { styled, useTheme, ThemeProvider, createTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import MuiDrawer from '@mui/material/Drawer';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';

import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';

import TopToolBar from './components/TopToolBar';
import NavBarMenu from './components/NavBarMenu';

import { BrowserRouter as Router, Routes, Route, useRoutes } from "react-router-dom";
import { isMobile } from 'react-device-detect';

import { useSnackbar } from 'notistack';

import Home from 'pages/Home';
import Events from 'pages//events/Events';
import Clients from 'pages/client/Clients';
import Employees from 'pages/employee/Employees';
import Material from 'pages/Material';
import Users from 'pages/users/Users';
import Settings from 'pages/settings/Settings';
import Schedule from 'pages/schedule/Schedule';
import Page404 from 'pages/Page404';

import Progress from 'components/Progress'

const queryClient = new QueryClient({
  defaultOptions:{
    queries:{
      refetchOnWindowFocus:false,
      retry:2,
      staleTime:5 * 60 * 1000
    },
  }
})

const drawerWidth = 240;
const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    width: drawerWidth,
    zIndex: theme.zIndex.drawer - 1,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    ...(open && {
      ...openedMixin(theme),
      '& .MuiDrawer-paper': openedMixin(theme),
    }),
    ...(!open && {
      ...closedMixin(theme),
      '& .MuiDrawer-paper': closedMixin(theme),
    }),
  }),
);

export default function AppAuthRoutes(props) {

  const [mode, setMode] = React.useState(localStorage.getItem('theme_mode') ?? 'light');
  const [bodyBackground, setBodyBackground] = useState({ light: '#f1f1f1', dark: '#101010' });
  const theme = createTheme({
    components: {
      MuiCssBaseline: {
        styleOverrides: {
          body: {
            backgroundColor: (mode === 'light' ? bodyBackground.light : bodyBackground.dark),
          }
        }
      }
    },
    palette: {
      mode: mode,
    },
  });

  const changeTheme = () => {
    setMode((prevMode) => (prevMode === 'light' ? 'dark' : 'light'));
    localStorage.setItem('theme_mode', (mode === 'light' ? 'dark' : 'light'));
  }

  const { enqueueSnackbar } = useSnackbar();

  const [isLoadProgress, setLoadProgress] = useState(false);
  const [title, setTitle] = useState('Ecodent');
  const [open, setOpen] = React.useState(localStorage.getItem('is_open_menu') === 'true');

  const [user, setUser] = useState([]);

  const handlerLoadProgress = (param) => {
    setLoadProgress(param)
  }

  const errorLoadProgress = (param) => {
    enqueueSnackbar(param || 'Ошибка загрузки данных', { variant: 'error' });
  }




  const handleDrawerOpen = (open) => {
    setOpen(true);
    localStorage.setItem('is_open_menu', true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
    localStorage.setItem('is_open_menu', false);
  };

  const handleisAuth = () => {
    props.handleisAuth(false);
  }



  useEffect(() => {
    api.get(AppConfig.apiUrl + '/user/current')
      .then(response => {
        setUser(response.data);
      })
      .catch((error) => {
        errorLoadProgress('Ошибка загрузки данных');
      });
  }, [props.isAuth]);


  return (

    <ThemeProvider theme={theme}>
      <Context.Provider value={{
        isLoadProgress, handlerLoadProgress, errorLoadProgress, changeTheme, setTitle
      }}>
        <QueryClientProvider client={queryClient}>
          <ReactQueryDevtools />
          <Router>
            <Box sx={{ display: 'flex' }}>

              {isLoadProgress ? <Box sx={{ zIndex: 10000 }}><Progress /></Box> : ''}
              <CssBaseline />
              <AppBar position="fixed" open={open}>
                <Toolbar>
                  <IconButton
                    color="inherit"
                    aria-label="open drawer"
                    onClick={handleDrawerOpen}
                    edge="start"
                    sx={{
                      marginRight: 5,
                      ...(open && { display: 'none' }),
                    }}
                  >
                    <MenuIcon />
                  </IconButton>
                  <TopToolBar title={title} user={user} handleisAuth={handleisAuth} />
                </Toolbar>
              </AppBar>

              <Drawer variant="permanent" open={open}>

                <DrawerHeader>
                  <div>
                    <img src={logo} alt="My logo" style={{ height: '48px', 'float': 'left' }} />
                  </div>
                  <IconButton onClick={handleDrawerClose}>
                    {theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
                  </IconButton>
                </DrawerHeader>
                <Divider />
                <NavBarMenu open={open} setTitle={(item) => setTitle(item.title)} />
              </Drawer>

              <Box component="main" sx={{ flexGrow: 1, p: (isMobile ? 1 : 3) }}>
                <DrawerHeader />
                {/* <AppRoutes /> */}
                <Routes>
                  <Route path='/' element={<Home />} />
                  <Route path="/profile" element={<Home />} />
                  <Route path="/events" element={<Events />} />
                  <Route path="/clients" element={<Clients />} />
                  <Route path="/employees" element={<Employees />} />
                  <Route path="/material" element={<Material />} />
                  <Route path="/users" element={<Users />} />
                  <Route path="/settings" element={<Settings />}/>
                  <Route path="/schedule" element={<Schedule />} />
                  <Route path="*" element={<Page404 />} />
                </Routes>
              </Box>
            </Box>
          </Router>
        </QueryClientProvider>
      </Context.Provider>
    </ThemeProvider>
  );
}
