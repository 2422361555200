import React from "react"
import { useTheme, createTheme } from '@mui/material/styles';

export default function Page404() {
    const theme =useTheme();
    theme.components.MuiCssBaseline.styleOverrides.body.backgroundColor='#ffffff';

    const theme1 = createTheme({
        components: {
            MuiCssBaseline: {
                styleOverrides: {
                    body: {
                        backgroundColor: "#ffffff",
                    }
                }
            }
        }
    });

    return (
        <>Page404</>
    )
}