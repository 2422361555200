import React, { useEffect } from "react";
import { Context } from 'context';

import { useTheme, styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { Typography } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link, useLocation } from "react-router-dom";

import Specialization from "pages/settings/specialization/Specialization"
import Services from "pages/settings/services/Services"
import Filials from "pages/settings/filials/Filials"
import InsuranceSubjects from "pages/settings/insuranceSubjects/InsuranceSubjects"

import { GlobalStyles } from '@mui/material';

export default function Settings() {

    const location = useLocation();
    const theme = useTheme();

    const LinkItem = styled(Link)(({ theme }) => ({
        ...theme.typography.h5,
        padding: theme.spacing(1),
        display: 'inline-block',
        textAlign: 'center',
        color: theme.palette.text.secondary,
        '&:hover': {
            color: '#000000',
            cursor: 'pointer'
        }
    }));



    const loadSubComponent = () => {
        const hash = location.hash;
        switch (hash) {
            case '#specialization':
                return <Specialization />;
            case '#services':
                return <Services />;
            case '#insurance_subjects':
                return <InsuranceSubjects />;
            default:
                return <Filials />
        }
    }


    return (
        <>
            {/* (<GlobalStyles styles={{ body: { backgroundColor: '#ffffff' } }} /> */}
            <Box sx={{ flexGrow: 1 }}>
                <Grid container>
                    <Grid container item md={6} xs={12} spacing={2}>
                        <Grid item md={3} xs={6}>
                            <LinkItem to="#specialization">
                                <FontAwesomeIcon icon="fa-solid fa-user-doctor" />
                                <Typography>Специализации</Typography>
                            </LinkItem>
                        </Grid>
                        <Grid item md={3} xs={6}>
                            <LinkItem to="#services">
                                <FontAwesomeIcon icon="fa-solid fa-coins" />
                                <Typography>Услуги и цены</Typography>
                            </LinkItem>
                        </Grid>
                        <Grid item md={3} xs={6}>
                            <LinkItem to="#filials">
                                <FontAwesomeIcon icon="fa-solid fa-hospital" />
                                <Typography>Филиалы</Typography>
                            </LinkItem>
                        </Grid>
                        <Grid item md={3} xs={6}>
                            <LinkItem to="#insurance_subjects">
                                <FontAwesomeIcon icon="fa-solid fa-hospital" />
                                <Typography>Страховые компании</Typography>
                            </LinkItem>
                        </Grid>
                    </Grid>
                </Grid>
            </Box>

            <Box mt={2}>
                {loadSubComponent()}
            </Box>
        </>
    )
}