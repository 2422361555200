import api from 'helpers/api';
import { AppConfig } from 'config.js';
import { useQuery, useMutation, useQueryClient } from "react-query";


export const useFilials = () => {
    const loadFilials = async () => {
        return await api.get(AppConfig.apiUrl + '/filial');
    }

    const { data: data, isLoading, isError } = useQuery(
        ['filials'],
        loadFilials,
        {
            staleTime: 5 * 60 * 1000
        }
    );

    let filials = data?.data || [];
    return { filials, isLoading, isError }
}

export const useFilial = (id) => {
    const { data: data, isLoading, isFetching, isError, refetch, remove } = useQuery(
        ['filial', id],
        async () => {
            if (id === null) {
                return {}
            }
            else if (id === 'create') {
                return await api.get(AppConfig.apiUrl + '/filial/create');
            } else {
                return await api.get(AppConfig.apiUrl + '/filial/' + id);
            }
        }
    );
    return { data: data?.data || [], isLoading, isFetching, isError, refetch, remove }
}

export const useInvalidateFilial = () => {
    const queryClient = useQueryClient();
    const mutateAsync = async (id) => {
        queryClient.invalidateQueries(['filial', id])
    }
    return { mutateAsync }
}

export const useInvalidateFilials = () => {
    const queryClient = useQueryClient();
    const mutateAsync = () => {
        queryClient.invalidateQueries(['filials'])
    }
    return { mutateAsync }
}


export const useSaveFilial = () => {
    const queryClient = useQueryClient();
    const { isLoadingMutate, isError, mutateAsync } = useMutation(
        async (data) => {
            return await api.post(AppConfig.apiUrl + '/filial', data)
        },
        {
            onSuccess: async () => {
                queryClient.invalidateQueries(['filial', 'create'])
                queryClient.invalidateQueries(['filials'])
            }
        }
    )
    return { isLoadingMutate, isError, mutateAsync }
}
