import * as React from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';

export default function CircularIndeterminate() {

  const WrapperStyle = { width: '100%',height: '100%','backgroundColor': '#1565c029',position: 'fixed','zIndex': '1001' }
  const DivStyle = { position: 'fixed',top: '50%','left': '50%' }

  return (
    <Box style={WrapperStyle}>
      <div style={DivStyle}>
        <CircularProgress />
      </div>
    </Box>
  );
}
