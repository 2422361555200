import React, { useEffect, useContext, useState } from "react";
import { Context } from 'context';
import { useFilials, useFilial, useSaveFilial, useInvalidateFilials, useInvalidateFilial } from "hooks/filials/useFilials";
import { useFilialRoomTypes } from "hooks/filials/useFilialRoomTypes";
import { useWeekDays } from "hooks/useWeekDays";

import { Box } from "@mui/material";
import { Stack } from "@mui/system";

import FilialItem, { FilialAddItem } from "./FilialItem";
import FilialFormModal from "./FilialFormModal";
import FilialDeleteFormModal from "./FilialDeleteFormModal";

export default function Filials() {
    const { errorLoadProgress, setTitle } = useContext(Context)
    const [showEditModal, setShowEditModal] = useState(false)
    const [showDeleteModal, setShowDeleteModal] = useState(false)

    const [current_id, setCurrentId] = useState(null)
    const { filials, isError } = useFilials()
    const { filial_room_types, isError: isErrorFilialRoomTypes } = useFilialRoomTypes()
    const { data: filialForm, isLoading, isError: isErrorFilial } = useFilial(current_id)
    const { mutateAsync: invalidateFilials } = useInvalidateFilials()
    const { mutateAsync: invalidateFilial } = useInvalidateFilial()
    const { mutateAsync: saveFilial, isError: isErrorSave } = useSaveFilial()

    const { week_days, isError: isErrorWeekDays } = useWeekDays()


    const handleCreate = () => {
        setCurrentId('create')
        setShowEditModal(true);
    }

    const handleEdit = (id) => {
        setCurrentId(id);
        setShowEditModal(true);
    }

    const handleSave = (data) => {
        saveFilial(data).then(() => {
            setShowEditModal(false)
        })
    }

    const handleDeleteModal = (id) => {
        setShowDeleteModal(true);
        setCurrentId(id);
    }

    const handleDelete = (data) => {
        console.log(data)
    }

    const handleClose = (filial) => {
        invalidateFilial(filial.id).then(() => {
            setShowEditModal(false);
            setShowDeleteModal(false);
        })
    }

    const handleUpdateFilials = () => {
        invalidateFilials();
    }

    useEffect(() => {
        if (isError) {
            errorLoadProgress('Ошибка загрузки данных по филиалам')
        }
        if (isErrorWeekDays) {
            errorLoadProgress('Ошибка загрузки данных по дням недели')
        }
    }, [isError, isErrorWeekDays])

    useEffect(() => {
        if (isErrorFilial) {
            errorLoadProgress('Ошибка загрузки данных по филиалу')
        }
    }, [isErrorFilial])

    useEffect(() => {
        if (isErrorFilialRoomTypes) {
            errorLoadProgress('Ошибка загрузки типов комнат для филиала')
        }
    }, [isErrorFilialRoomTypes])

    
    useEffect(() => {
        setTitle('Список филиалов');
    }, [])

    return (
        <Box>
            <FilialFormModal
                filial={filialForm}
                room_types={filial_room_types}
                week_days={week_days}
                show={showEditModal}
                handleClose={handleClose}
                handleSave={handleSave}
                handleUpdateFilials={handleUpdateFilials}

            />
            <FilialDeleteFormModal
                filial={filialForm}
                show={showDeleteModal}
                handleClose={handleClose}
                handleDelete={handleDelete}
            />

            <Stack
                direction={{ xs: 'column', sm: 'column', md: 'row' }}
                spacing={{ xs: 1, sm: 2, md: 4 }}
            >
                {
                    filials?.map((item, index) => (
                        <FilialItem key={'filial_' + index} {...item} handleEdit={handleEdit} handleDeleteModal={handleDeleteModal} />
                    ))
                }
                <FilialAddItem handleCreate={handleCreate} />
            </Stack>

        </Box>
    )
}