import api from 'helpers/api';
import { AppConfig } from 'config.js';
import { useQuery } from "react-query";

export const useInsuranceSubjects = () => {

    const { data: data, isLoading, isError } = useQuery(
        ['insuranceSubject'],
        async () => {
            return await api.get(AppConfig.apiUrl + '/insurance/insuranceSubject');
        }
    );

    let result = [];
    data?.data.map((item, index) => {
        result.push({ value: item.reg_number, text: item.short_name });
    });

    return { data: data?.data || [], selectItems: result, isLoading, isError }
}

export const useInsuranceSubject = (id) => {
    const { data: data, isLoading, isFetching, isError, refetch, remove } = useQuery(
        ['filial', id],
        async () => {
            if (id === null) {
                return {}
            }
            else if (id === 'create') {
                return await api.post(AppConfig.apiUrl + '/insurance/insuranceSubject');
            } else {
                return await api.get(AppConfig.apiUrl + '/insurance/insuranceSubject/' + id);
            }
        }
    );
    return { data: data?.data || [], isLoading, isFetching, isError, refetch, remove }
}

