import React, { useState } from 'react';
import { AppConfig } from 'config.js';

import { Grid, Box, Paper, TextField, Typography, Link, } from '@mui/material'
import FormControlLabel from '@mui/material/FormControlLabel';
import LoadingButton from '@mui/lab/LoadingButton';
import Checkbox from '@mui/material/Checkbox';
import AuthHeader from 'pages/auth/AuthHeader.js';

import icon from 'assets/icons/free-icon-authorization-4924714.png';


export default function Login(props) {


    const [username, setUserName] = useState('');
    const [password, setPassword] = useState('');
    const [isWrongAuth, setIsWrongAuth] = useState(false);
    const [loading, setLoading] = React.useState(false);

    
    const sendAuthRequest = () => {
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        var oauth_url = AppConfig.oauthUrl + '/oauth/token';

        var raw = JSON.stringify(
            {
                "username": username,
                "password": password,
                "grant_type": "password",
                "client_id": AppConfig.client_id,
                "client_secret": AppConfig.client_secret
            }
        );

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        };

        setLoading(true);
        fetch(oauth_url, requestOptions)
            .then(function (response) {
                if (!response.ok) {
                    setIsWrongAuth(true);
                    throw new Error("HTTP status " + response.status);
                }
                setLoading(false);
                return response.json();
            })
            .then(result => {
                localStorage.setItem('access_token', result.access_token);
                localStorage.setItem('refresh_token', result.refresh_token);
                props.handleisAuth(true);
                setLoading(false);
            })
            .catch(error => {
                setLoading(false);
            });
    }


    const handlerKeyDown = (event) => {
        if (event.key === 'Enter') {
            event.preventDefault();
            event.stopPropagation();
            sendAuthRequest();
        }
    }


    return (
        <Paper elevation={10} style={props.paperStyle}>
            <Box p={3}>
                <Grid align='center'>
                    <AuthHeader src={icon} title='Вход' variant="square" />
                </Grid>

                <Box component="form" sx={{ "& .MuiTextField-root": { mt: 3 } }} noValidate >
                    <div>
                        <TextField label='Пользователь ' onChange={(e) => { setUserName(e.target.value); setIsWrongAuth(false) }} placeholder='Введите логин пользователя'
                            fullWidth required={!isWrongAuth} error={isWrongAuth} />
                    </div>
                    <div>
                        <TextField label='Пароль'
                            onChange={(e) => { setPassword(e.target.value); setIsWrongAuth(false) }}
                            onKeyDown={handlerKeyDown}
                            placeholder='Введите пароль'
                            type='password'
                            helperText={isWrongAuth ? 'ошибка авторизации' : ''}
                            required={!isWrongAuth}
                            fullWidth
                            error={isWrongAuth} />
                    </div>
                </Box>
                <FormControlLabel
                    control={
                        <Checkbox
                            name="checkedB"
                            color="primary"
                        />
                    }
                    label="Запомнить меня"
                />
                <Box mt={2}>
                    <LoadingButton type='submit'
                        color='primary'
                        variant="contained"
                        onClick={sendAuthRequest}
                        loading={loading}
                        loadingIndicator="Авторизация"
                        fullWidth >
                        Войти
                    </LoadingButton>
                </Box>
                <Box mt={2}>
                    <Grid container mt={2} spacing={0}>
                        <Grid item xs={12} sm={6}>
                            <Typography sx={{ textAlign: 'center' }}>
                                <Link href="/AuthRecoveryRequest" >
                                    забыли пароль?
                                </Link>
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Typography sx={{ textAlign: 'center' }}>
                                <Link href="/AuthRegistration" >
                                    регистрация
                                </Link>
                            </Typography>
                        </Grid>
                    </Grid>
                </Box>
            </Box>
        </Paper>
    )
}
