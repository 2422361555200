import api from 'helpers/api';
import { AppConfig } from 'config.js';
import { useQuery } from "react-query";

export const useUsers = () => {


    const { data: data, isLoading, error, isError } = useQuery(
        ['users'],
        async () => {
            return await api.get(AppConfig.apiUrl + '/user');
        }
    );

    let users = data?.data || [];
    return { users, isLoading, error, isError }
}